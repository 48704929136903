export function isLoggedIn(cookies) {
    return cookies.get('token') != null;
}

export function isNoDayUseCount(userInfo) {
    console.log("isNoDayUseCount", userInfo);

    if (userInfo && userInfo.vipLevel === 0 && userInfo.dayUseCount === 0) {
        console.log("isNoDayUseCount true");
        return true;
    }

    console.log("isNoDayUseCount false");
    return false;
}