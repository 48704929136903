<template>
  <div class="upload-area">
    <div class="upload-demo upload-wrap">
      <Upload ref="uploadRef" v-loading="loading" :element-loading-text="loadingText"
        element-loading-background="rgba(0, 0, 0, 0.2)" class="upload-demo" drag multiple list-type="picture"
        :file-list="fileList" :auto-upload="false" action="" :on-preview="handlePreview" :on-change="handleChange"
        :on-remove="handleRemove" :on-exceed="handleExceed" :before-upload="handleBeforeUpload" :limit="limitCount"
        :show-file-list="showFileList" :on-picocr="handlePicOcr" :on-picedit="handlePicEdit"
        :on-checkbox="handleCheckbox" accept="image/jpeg,image/png,image/bmp" :activeTab="activeTab">
      </Upload>

      <div v-if="fileList.length > 0 && !ocrInProgress && !ocrSuccess" class="checkall-wrap">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      </div>

      <UploadTip :show="fileList.length == 0" :activeTab="activeTab"></UploadTip>

      <div class="btn-ocr" :class="{ 'btn-ocr-active': fileList.length > 0 && !ocrInProgress }"
        @click.stop="handleStartOCR">{{ btnTxt }}
      </div>

      <div class="radio-wrap">
        <el-radio-group v-model="radio" @input="handleRadioChange" :disabled="!fileList.length">
          <el-radio-button label="正序"></el-radio-button>
          <el-radio-button label="倒序"></el-radio-button>
          <el-radio-button label="清空"></el-radio-button>
        </el-radio-group>

        <FilePos :fileList="fileList"></FilePos>
      </div>
    </div>

    <PreviewDocx ref="previewDocx" v-if="previewDocxVisible" :tabName="tabName" @orc="handlePreviewPicOcr"
      :activeTab="activeTab">
    </PreviewDocx>
    <ImageEditModal v-if="showModal" :show="showModal" :imageUrl="imageSrc" @close="closeModal"
      @cropped="handleCroppedImage" />
    <PopTxtSaveType ref="popTxtSaveType" v-if="popTxtSaveTypeVisible" :fileList="fileList" @mergeDown="handleMergeDown">
    </PopTxtSaveType>
  </div>
</template>

<script>
import Vue from 'vue';
import Upload from './../upload';
import UploadTip from './../common/UploadTip';
import FilePos from './../common/FilePos';
import ImageEditModal from './../ImageEditModal.vue';
import PreviewDocx from './../PreviewDocx.vue';
import { downloadFile } from '@/utils/download.js'
import PopTxtSaveType from './../common/PopTxtSaveType.vue';
import { isLoggedIn, isNoDayUseCount } from '@/utils/authUtils';
import '@/utils/dateUtils.js';

const OSS = require('ali-oss');
const MAX_IMAGE = 10000;


export default {
  components: {
    Upload,
    UploadTip,
    ImageEditModal,
    PreviewDocx,
    FilePos,
    PopTxtSaveType
  },
  name: 'UploadArea',
  props: {
    activeTab: {
      type: String,
      required: true,
    },
    tabName: String,
  },
  data() {
    return {
      toFileType: 0,
      ocrSuccess: false,
      ocrInProgress: false,
      btnTxt: "开始识别",
      dialogVisible: false,
      loading: false,
      loadingText: '',
      limitCount: MAX_IMAGE,
      showFileList: true,
      fileList: [],
      files:[],
      mainTaskIdList: [],
      showModal: false,
      imageSrc: '',
      cropperFile: null,
      radio: '正序',
      previewDocxVisible: false,
      pdf2ImagesIng: false, //pdf转图片中
      isIndeterminate: false,
      checkAll: false,
      popTxtSaveTypeVisible: false,
    };
  },
  mounted() {
    if (this.tabName == 'Img2Word') {
      this.toFileType = 0;
    } else if (this.tabName == 'Img2Excel' || this.tabName == 'ImgExTable') {
      this.toFileType = 1;
    } else if (this.tabName == 'Img2Txt' || this.tabName == 'ImgExTxt') {
      this.toFileType = 2;
    }
  },
  methods: {
    checkUserVip() {
      if (!isLoggedIn(this.$cookies)) {
        console.log("未登录");
        this.$emit('notify', { message: '未登录', type: 'login' });
        return false;
      }

      if (isNoDayUseCount(this.$H.userInfo)) {
        console.log("升级会员");
        this.$emit('notify', { message: '升级会员', type: 'purchase' });
        return false;
      }  

      return true;
    },
    handleStartOCR() {
      if (!this.checkUserVip()) {
        return;
      }

      if (this.fileList.length == 0 || this.ocrInProgress) {
        return;
      }

      if (this.btnTxt == "合并下载") {
        if (this.tabName == 'ImgExTxt') {
          this.showPopTxtSaveType();
        } else {
          this.mergeDown();
        }

        return;
      }

      this.ocrInProgress = true;
      this.showLoading();
      let fileList = [];
      this.fileList.forEach(item => {
        if (item.checked)
          fileList.push(item);
      });
      this.getAliOssAuth(fileList);
    },
    handlePreview(file) {
      console.log("handlePreview", file, this.$refs.previewDocx);
      this.previewDocxVisible = true;
      this.$nextTick(() => {
        this.$refs.previewDocx.init(file);
      });
    },
    handleChange(file, fileList) {
      console.log("handleChange file", file);
      console.log("handleChange", fileList);
      const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/bmp'];
      if (file.raw && !acceptedImageTypes.includes(file.raw.type)) {
        this.$message.error('请选择图片文件');
        this.fileList = [];
        return false;
      }

      if (file && file.size && file.size > 50 * 1024 * 1024) {//52428800
        this.$message.error('单个文件仅支持50M以内，请重新上传。');
        this.fileList = [];
        return false;
      }

      var done = () => {
        this.fileList = fileList;
        let page = 1;
        this.fileList.map(file => {
          if (file.checked == undefined) {     
            file.checked = true;
          }

          file.page = page;
          page++;
        });
        
        this.handleCheckbox();
      };

      if (this.ocrSuccess || this.ocrInProgress) {
        this.fileList = fileList.splice(0, fileList.length - 1);
        this.$confirm('上传新文档需清空当前文档，是否继续？')
          .then(()=> {
            this.initOcr();
            done();
          })
          .catch(()=> { });
          return;
        // this.$message.error('请先清空当前任务！');
        // return false;
      }

      done();
    },
    handleRemove(file, fileList) {
      console.log("handleRemove");
      this.fileList = fileList;

      if (fileList.length == 0) {
        this.initOcr();
      }
    },
    handleExceed(files, fileList) {
      console.log("handleExceed files", files, typeof(files));
      if (files.length > this.limitCount) {
        this.$message.warning(`最多只能选择${this.limitCount}个文件`);
      }

      if (this.ocrSuccess) {
        this.$message.error('请先清空再上传！');
      }
    },
    handleBeforeUpload() {
      console.log("handleBeforeUpload");
    },
    initOcr() {
      this.limitCount = MAX_IMAGE;
      this.ocrSuccess = false;
      this.ocrInProgress = false;
      this.fileList = [];
      this.btnTxt = "开始识别";
      this.stopAllInterval();
    },
    handleResetOCR() {
      this.initOcr();
      this.$nextTick(() => {
        this.radio = '正序';
      });
      
    },
    handlePreviewPicOcr(file) {
      this.curOcrFile = file;

      this.handlePicOcr(file);
    },
    handlePicOcr(file) {
      console.log("handlePicOcr file", file);
      if (!this.checkUserVip()) {
        console.log("checkUserVip false");
        return;
      }

      this.showLoading("识别中，请稍等...")
      this.ocrInProgress = true;
      file.ocrInProgress = true;
      Vue.set(this.fileList, this.fileList.indexOf(file), file);
      // this.showLoading("开始上传文件");
      let fileList = [file];
      this.getAliOssAuth(fileList);
    },
    handlePicEdit(file) {
      console.log("handlePicEdit file", file);
      this.openModal(file);
    },
    handleRadioChange(radio) {
      console.log("handleRadioChange", radio)
      if (radio == '正序' || radio == '倒序') {
        if (this.fileList && this.fileList.length > 0)
          this.fileList.reverse();
      } else if (radio == '清空') {
        setTimeout(()=>{
          this.handleResetOCR();
        }, 500);
      }
    },
    handleCheckbox() {
      console.log("UploadArea handleCheckbox");
      let checkedCount = 0;
      this.fileList.forEach(file => {
        if (file.checked) {
          checkedCount++;
        }
      });

      this.checkAll = checkedCount == this.fileList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.fileList.length;
    },
    handleCheckAllChange(val) {
      this.isIndeterminate = false;
      this.fileList.forEach(file => {
        file.checked = val;
      });
    },
    async getAliOssAuth(fileList) {
      console.log("getAliOssAuth fileList", fileList);
      // this.showLoading("开始上传");
      if (fileList.length == 0) return;

      let res = await this.$api.getOssAuth({});
      if (res.data.code == 0) {
        const uploadPromises = fileList.map(file => this.uploadFile(res.data.data, file));
        await Promise.all(uploadPromises);
        console.log("All files uploaded successfully fileList", fileList);
        
        // this.showLoading("上传文件成功，开始OCR识别");
        const files = fileList.map(item => ({
          fileUrl: item.fileUrl
        }));
        if (!this.isPreviewDialogVisible()) {
          console.log("getAliOssAuth hideLoading");
          this.hideLoading();
        }

        const uploadFiles = files.filter(item => item.fileUrl);
        
        this.startOCR(uploadFiles); 
      } else {
        this.hideLoading();
        this.$message.error(res.data.msg);
      }
      
    },
    async uploadFile(data, file) {
      console.log("uploadFile", file);
      let obj = data;
      const client = new OSS({
        // yourRegion填写Bucket所在地域。Region填写为oss-cn-hangzhou。
        region: obj.region,
        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
        accessKeyId: obj.accessKeyId,
        accessKeySecret: obj.accessKeySecret,
        // 从STS服务获取的安全令牌（SecurityToken）。
        stsToken: obj.securityToken,
        // 填写Bucket名称。
        bucket: obj.bucket
      })

      try {
        var date = new Date();
        var dir;
        const randomNum = Math.floor(Math.random() * 100000);
        let uid = this.$H.userInfo.uid;
        if (uid == undefined) {
          uid = "0000";
        }
        dir = `upload/UID${uid}/${date.format("yyyyMMdd")}/${date.getTime()}-${randomNum}.jpg`;
        const result = await client.put(dir, file.raw);
        console.log('Upload successful', result);
        file.fileUrl = result.url;
      } catch (error) {
        console.error('Error uploading file', error);
      }
    },
    /**
     * 开始OCR识别
     * files: [{fileUrl: "xxxx"}]
     */
    async startOCR(files) {
      let res = await this.$api.execute({ files: files, toFileType: this.toFileType });
      console.log("startOCR", res)
      if (res.data.code === 0) {
        this.$emit('notify', { message: '更新用户信息', type: 'userinfo' });
        files.map((item, index) => {
          let file = this.fileList.find(item2 => item2.fileUrl == item.fileUrl);
          if (index < res.data.executeCount) {
            file.ocrInProgress = true;
          }
          Vue.set(this.fileList, this.fileList.indexOf(file), file);
        });

        let intervalId = setInterval(() => {
          this.checkProgress(res.data.mainTaskId);
        }, 500);

        this.mainTaskIdList.push({ 
          mainTaskId: res.data.mainTaskId,
          intervalId: intervalId
         });
      } else if (res.data.code === 401) {
        this.$emit('notify', { message: '未登录', type: 'login' });
      } else if (res.data.code === 600) {
        this.$emit('notify', { message: '升级会员', type: 'purchase' });
      } else {
        this.$message.error(res.data.msg);
        this.hideLoading();
      }
    },
    isPreviewDialogVisible() {
      return this.$refs.previewDocx && this.$refs.previewDocx.dialogVisible;
    },
    stopAllInterval() {
      this.mainTaskIdList.map(item => {
        clearInterval(item.intervalId);
      });
    },
    async checkProgress(mainTaskId) {
      // console.log("checkProgress")
      try {
        const data = await this.getFileProgress(mainTaskId);

        let intervalId = this.mainTaskIdList.find(item => item.mainTaskId == data.taskInfo.mainTaskId).intervalId;

        let allFinish = true;
        data.taskInfo.files.map(item => {
          if (item.errorCode == '') {
            allFinish = false;
          }
          let file = this.fileList.find((fileItem) => fileItem.fileUrl === item.fileUrl)
          if (file) {
            file.resultUrl = item.resultUrl;
            file.progress = item.progress;
            file.errorCode = item.errorCode;
            file.ocrSuccess = item.errorCode != '';
            file.ocrInProgress = !file.ocrSuccess;
            file.mainTaskId = mainTaskId;
            Vue.set(this.fileList, this.fileList.indexOf(file), file);
            
            if (this.isPreviewDialogVisible() && this.$refs.previewDocx && this.$refs.previewDocx.file == file) {
              console.log("checkProgress", this.$refs.previewDocx.file, file)
              if (file.errorCode != '' && file.errorCode != 'OK' && !this.$refs.previewDocx.showMessageError) {
                this.$refs.previewDocx.showMessageError = true;
                this.$message.error("识别失败");
              }

              if (file.errorCode == 'OK' && !this.$refs.previewDocx.ocrWordUrl) {
                this.$refs.previewDocx.init(file);
              }

              if (file.errorCode != '') {
                this.hideLoading();
              }
            }
          } else {
            console.log("checkProgress 没有找到对应图片")
          }
        })

        if (allFinish) {
          console.log('Progress is 100%. Stopping timer.');
          clearInterval(intervalId); // 停止定时器
          this.mainTaskIdList = this.mainTaskIdList.filter(item => item.intervalId !== intervalId);
          this.hideLoading();
          this.ocrSuccess = true;
          this.ocrInProgress = false;
          // this.limitCount = this.fileList.length;
          this.$emit('notify', { message: '更新用户信息', type: 'userinfo' });
          
          this.btnTxt = "合并下载";
        }
      } catch (error) {
        this.hideLoading();
        console.error('Error checking progress:', error);
      }
    },
    async getFileProgress(mainTaskId) {
      console.log("getFileProgress")
      let res = await this.$api.getfileProgress({ mainTaskId: mainTaskId });
      if (res.data.code === 0) {
        // this.showLoading(`当前进度${res.data.taskInfo.progress}%`);
        return res.data;
      }

      return 0;
    },
    handleMergeDown(txtType) {
      console.log("handleMergeDown", txtType);
      this.mergeDown(txtType == 1 ? 'doc' : '');
    },
    async mergeDown(fileType) {
      this.showLoading();
      let mainTaskIds = []
      this.fileList.map(item => {
        if (item.errorCode == 'OK') {
          mainTaskIds.push(item.mainTaskId);
        }
      })

      if (mainTaskIds.length == 0) {
        this.$message.error("没有可以合并下载项");
        this.hideLoading();
        return;
      }

      console.log("mergeDown", mainTaskIds, typeof(mainTaskIds));

      let res = await this.$api.mergeDown({ mainTaskIds: mainTaskIds, toFileType: this.toFileType });
      if (res.data.code === 0) {
        // window.open(res.data.mergeUrl);
        let mergeUrl = res.data.mergeUrl;
        let fileName = mergeUrl.substring(mergeUrl.lastIndexOf('/') + 1);
        console.log(fileName);
        if (fileType == 'doc') {
          fileName = fileName.replace(/\.txt$/, ".doc");
        }
        downloadFile(mergeUrl, fileName);
      } else {
        this.$message.error(res.data.msg);
      }
      this.hideLoading();
    },
    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    showLoading(text) {
      // this.loading = true;
      // this.loadingText = text;
      this.fullscreenLoading = this.$loading({
        lock: true,
        text: text,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    hideLoading() {
      // this.loading = false;
      if (this.fullscreenLoading)
        this.fullscreenLoading.close();
    },
    openModal(file) {
      this.showModal = true;
      this.imageSrc = file.srcUrl || file.url;
      this.cropperFile = file;
    },
    closeModal() {
      this.showModal = false;
    },
    handleCroppedImage(croppedImage) {
      console.log('裁剪后的图片:', croppedImage);
      // 你可以在这里上传裁剪后的图片到 OSS 或其他存储
      if (!this.cropperFile.srcUrl) {
        this.cropperFile.srcUrl = this.cropperFile.url;
        this.cropperFile.srcRaw = this.cropperFile.raw;
      }
      this.cropperFile.url = croppedImage;
      this.cropperFile.raw = this.dataURItoBlob(croppedImage);
      Vue.set(this.fileList, this.fileList.indexOf(this.cropperFile), this.cropperFile);
      this.closeModal();
    },
    showPopTxtSaveType() {
      this.popTxtSaveTypeVisible = true;
      this.$nextTick(() => {
        this.$refs.popTxtSaveType.init();
      });
    },
  }
}
</script>

<style scoped>
@import '@/assets/css/uploadarea.css';
</style>