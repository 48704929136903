import OSS from 'ali-oss';
import './dateUtils.js';

export async function getOssAuth() {
  try {
    const res = await this.$api.getOssAuth({}); // 替换为你的 API 地址
    if (res.data.code == 0) {
      return res.data.data;
    } else {
      throw new Error('获取 OSS 授权失败');
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

// 上传文件到阿里云 OSS
export async function uploadFile(ossAuth, file, fileSuffix, uid) {
  const client = new OSS({
    // yourRegion填写Bucket所在地域。Region填写为oss-cn-hangzhou。
    region: ossAuth.region,
    // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
    accessKeyId: ossAuth.accessKeyId,
    accessKeySecret: ossAuth.accessKeySecret,
    // 从STS服务获取的安全令牌（SecurityToken）。
    stsToken: ossAuth.securityToken,
    // 填写Bucket名称。
    bucket: ossAuth.bucket
  });

  try {
    var date = new Date();
    const randomNum = Math.floor(Math.random() * 100000);
    const dir = `upload/UID${uid}/${date.format("yyyyMMdd")}/OTHER/${date.getTime()}-${randomNum}${fileSuffix}`;
    console.log('文件上传路径', dir);

    const result = await client.put(dir, file.raw);
    console.log('文件上传成功', result);
    file.fileUrl = result.url;
  } catch (error) {
    console.error('文件上传失败', error);
  }
}