<template>
  <div class="upload-area">
    <div class="upload-demo upload-wrap">
      <div class="preview-wrap">
        <img class="preview-img" src="https://p2w.oss-cn-hangzhou.aliyuncs.com/upload/20241112/1731390336233-82099.jpg" alt="">

        <div class="img-view">

        </div>

        <div class="img-upload">
          上传图片
        </div>
      </div>

      <div class="watermark-wrap">
        <div class="watermark-input-wrap">
          <div>文本</div>
          <el-input placeholder="请输入内容" v-model="inputWaterMark" :disabled="true"></el-input>
        </div>

        <div class="watermark-set-wrap">
          设定自定义

          <div class="watermark-setitem-wrap">
            <div class="watermark-set-item">字号</div>
            <div class="watermark-set-item">旋转</div>
          </div>
          <div class="watermark-setitem-wrap">
            <div class="watermark-set-item">不透明度</div>
            <div class="watermark-set-item">多行水印</div>
          </div>
          <div class="watermark-setitem-wrap">
            <div class="watermark-set-item">垂直对齐</div>
            <div class="watermark-set-item">水平对齐</div>
          </div>
        </div>

        <div class="download-btn">下载文档</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { jsPDF } from 'jspdf';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { isLoggedIn, isNoDayUseCount } from '@/utils/authUtils';

const MAX_IMAGE = 10000;

export default {
  components: {

  },
  name: 'ImgWaterMark',
  props: {
    activeTab: {
      type: String,
      required: true,
    },
    tabName: String,
  },
  data() {
    return {
      inputWaterMark: '',
    };
  },
  methods: {
    checkUserVip() {
      if (!isLoggedIn(this.$cookies)) {
        console.log("未登录");
        this.$emit('notify', { message: '未登录', type: 'login' });
        return false;
      }

      if (isNoDayUseCount(this.$H.userInfo)) {
        console.log("升级会员");
        this.$emit('notify', { message: '升级会员', type: 'purchase' });
        return false;
      }

      return true;
    },
    handleExport(type) {
      console.log("handleExport");
      if (!this.checkUserVip()) {
        return;
      }

      if (this.fileList.length == 0) {
        return;
      }

      this.getZip(type);
    },
    handleChange(file, fileList) {
      console.log("handleChange file", file);
      console.log("handleChange", fileList);
      const acceptedImageTypes = ['image/jpeg', 'image/png', 'image/bmp'];
      if (file.raw && !acceptedImageTypes.includes(file.raw.type)) {
        this.$message.error('请选择图片文件');
        this.fileList = [];
        return false;
      }

      var done = () => {
        this.fileList = fileList;
        let page = 1;
        this.fileList.map(file => {
          if (file.checked == undefined) {
            file.checked = true;
          }

          file.page = page;
          page++;
        });

        this.handleCheckbox();
      };

      if (this.ocrSuccess || this.ocrInProgress) {
        this.fileList = fileList.splice(0, fileList.length - 1);
        this.$confirm('上传新文档需清空当前文档，是否继续？')
          .then(() => {
            this.initOcr();
            done();
          })
          .catch(() => { });
        return;
        // this.$message.error('请先清空当前任务！');
        // return false;
      }

      done();
    },
    handleRemove(file, fileList) {
      console.log("handleRemove");
      this.fileList = fileList;

      if (fileList.length == 0) {
        this.initOcr();
      }
    },
    initOcr() {
      this.limitCount = MAX_IMAGE;
      this.ocrSuccess = false;
      this.ocrInProgress = false;
      this.posInput = '';
      this.fileList = [];
      this.btnTxt = "开始识别";
      this.stopAllInterval();
    },
    handleResetOCR() {
      this.initOcr();
      this.$nextTick(() => {
        this.radio = '正序';
      });

    },
    handlePicEdit(file) {
      console.log("handlePicEdit file", file);
      this.openModal(file);
    },
    handleRadioChange(radio) {
      console.log("handleRadioChange", radio)
      if (radio == '正序' || radio == '倒序') {
        if (this.fileList && this.fileList.length > 0)
          this.fileList.reverse();
      } else if (radio == '清空') {
        setTimeout(() => {
          this.handleResetOCR();
        }, 500);
      }
    },
    handleCheckbox() {
      console.log("UploadArea handleCheckbox");
      let checkedCount = 0;
      this.fileList.forEach(file => {
        if (file.checked) {
          checkedCount++;
        }
      });

      this.checkAll = checkedCount == this.fileList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.fileList.length;
    },
    handleCheckAllChange(val) {
      this.isIndeterminate = false;
      this.fileList.forEach(file => {
        file.checked = val;
      });
    },
    handleFilePos() {
      if (this.fileList.length == 0)
        return;

      if (this.posInput < 0 || this.posInput > this.fileList.length) {
        if (this.posInput > this.fileList.length) {
          this.$message.error('超过当前文档最大页码，当前文档最大页码为 ' + this.fileList.length);
        } else {
          this.$message.error('请输入正确的页码！');
        }
        return;
      }

      this.fileList.forEach(file => {
        file.selected = false;
      });
      this.fileList[this.posInput - 1].selected = true;
      Vue.set(this.fileList, this.posInput - 1, this.fileList[this.posInput - 1]);

      const element = document.getElementById(`item-${this.fileList[this.posInput - 1].page}`);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    },
    isPreviewDialogVisible() {
      return this.$refs.previewDocx && this.$refs.previewDocx.dialogVisible;
    },
    stopAllInterval() {
      this.mainTaskIdList.map(item => {
        clearInterval(item.intervalId);
      });
    },
    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    showLoading(text) {
      this.fullscreenLoading = this.$loading({
        lock: true,
        text: text,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    hideLoading() {
      if (this.fullscreenLoading)
        this.fullscreenLoading.close();
    },
    openModal(file) {
      this.showModal = true;
      this.imageSrc = file.srcUrl || file.url;
      this.cropperFile = file;
    },
    closeModal() {
      this.showModal = false;
    },
    handleCroppedImage(croppedImage) {
      console.log('裁剪后的图片:', croppedImage);
      // 你可以在这里上传裁剪后的图片到 OSS 或其他存储
      if (!this.cropperFile.srcUrl) {
        this.cropperFile.srcUrl = this.cropperFile.url;
        this.cropperFile.srcRaw = this.cropperFile.raw;
      }
      this.cropperFile.url = croppedImage;
      this.cropperFile.raw = this.dataURItoBlob(croppedImage);
      Vue.set(this.fileList, this.fileList.indexOf(this.cropperFile), this.cropperFile);
      this.closeModal();
    },
    async generatePDFsAndZip(fileList) {
      console.log("generatePDFsAndZip", fileList);
      const selectedFiles = fileList.filter(file => file.checked);

      const zip = new JSZip();
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i].raw;

        // 使用 FileReader 将 File 对象读取为 DataURL 格式
        const imgDataUrl = await this.readFileAsDataURL(file);

        // 使用 jsPDF 创建 PDF
        const pdf = new jsPDF();

        // 创建一个 Image 对象并加载 DataURL
        const img = new Image();
        img.src = imgDataUrl;

        // 等待图片加载完成，获取图片的原始宽高
        await new Promise((resolve, reject) => {
          img.onload = () => resolve();
          img.onerror = reject;
        });

        // 获取图片的原始宽高
        const imgWidth = img.width;
        const imgHeight = img.height;

        console.log("generatePDFsAndZip pdf", pdf);
        // 将图片添加到 PDF 文件，保持原始尺寸
        // 图片按比例插入（无需拉伸或压缩），根据图片的实际宽高
        const pageWidth = pdf.internal.pageSize.width;
        const pageHeight = pdf.internal.pageSize.height;

        // 确保图片不会超出页面范围
        let ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);
        let scaledWidth = imgWidth * ratio;
        let scaledHeight = imgHeight * ratio;

        // 添加图片到当前页面，保持图片原始比例
        pdf.addImage(imgDataUrl, 'JPEG', 0, 0, scaledWidth, scaledHeight);

        // 将 PDF 保存到压缩包中
        zip.file(`${selectedFiles[i].page}.pdf`, pdf.output('blob'));
      }

      // 生成并下载压缩包
      var that = this;
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, 'pdf.zip');
        that.hideLoading();
      });
    },
    async generatePDF(fileList) {
      const selectedFiles = fileList.filter(file => file.checked);
      const pdf = new jsPDF();

      // 遍历所有图片，将每张图片添加到 PDF
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i].raw;

        // 将 File 对象转换为 DataURL（Base64 编码的图片）
        const imgDataUrl = await this.readFileAsDataURL(file);

        // 获取图片的宽度和高度
        const img = new Image();
        img.src = imgDataUrl;

        // 等待图片加载完毕后获取其尺寸
        await new Promise((resolve, reject) => {
          img.onload = () => resolve();
          img.onerror = reject;
        });

        const imgWidth = img.width;
        const imgHeight = img.height;

        // 如果是第一页，直接添加图片，如果是后面的页面，需要添加新的页面
        if (i > 0) {
          pdf.addPage();
        }

        // 将图片添加到 PDF 文件，保持原始尺寸
        // 图片按比例插入（无需拉伸或压缩），根据图片的实际宽高
        const pageWidth = pdf.internal.pageSize.width;
        const pageHeight = pdf.internal.pageSize.height;

        // 确保图片不会超出页面范围
        let ratio = Math.min(pageWidth / imgWidth, pageHeight / imgHeight);
        let scaledWidth = imgWidth * ratio;
        let scaledHeight = imgHeight * ratio;

        // 添加图片到当前页面，保持图片原始比例
        pdf.addImage(imgDataUrl, 'JPEG', 0, 0, scaledWidth, scaledHeight);
      }

      // 下载 PDF 文件
      pdf.save('images.pdf');
      this.hideLoading();
    },
    // 将 File 转换为 DataURL
    readFileAsDataURL(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    },
    async getZip(type) {
      this.showLoading();
      let res = await this.$api.getZip({ zipType : 1});

      if (res.data.code == 0) {
        if (type == 0) {
          this.generatePDFsAndZip(this.fileList);
        } else if (type == 1){
          this.generatePDF(this.fileList);
        }
      } else {
        this.hideLoading();
        this.$message.error(res.data.msg);
      }
    },
  }
}
</script>

<style scoped>
@import '@/assets/css/uploadarea.css';

.upload-wrap {
  display: flex;
  flex-direction: row;
}

.preview-img {
  width: 400px;
  height: 566px;
}

.watermark-input-wrap {
  width: 1006px;
  height: 138px;
  border-radius: 10px;
  border: 1px solid #D6D6D6;

  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  text-align: center;
  font-style: normal;
}

.watermark-set-wrap {
  width: 1006px;
  height: 470px;
  border-radius: 10px;
  border: 1px solid #D6D6D6;

  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  text-align: center;
  font-style: normal;
}

.download-btn {
  width: 400px;
  height: 60px;
  background: #CCCCCC;
  border-radius: 10px;

  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 60px;
  text-align: center;
  font-style: normal;
}

.btn-wrap {
  
  z-index: 1;
  width: 410px;
  height: 50px;
  position: absolute;
  top: 41px;
  right: 32px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn-img {
  width: 200px;
  height: 50px;
  background: #CBCBCB;
  border-radius: 10px;

  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 50px;
    text-align: center;
    font-style: normal;
}

.btn-img-active {
  background: #0769F6;
}
</style>