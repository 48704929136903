<template>
  <div class="upload-area">
    <div class="upload-demo upload-wrap">
      <Upload ref="uploadRef" class="upload-demo" drag multiple list-type="picture" :file-list="fileList"
        :auto-upload="false" action="" :on-change="handleChange" :limit="limitCount" :show-file-list="false"
        accept="application/pdf" :activeTab="activeTab">
      </Upload>

      <div v-show="formData.fileList.length > 0" class="table-wrap">
        <el-form ref="fileListForm" :model="formData" label-width="0px" style="width: 100%;">
          <el-table ref="multipleTable" :data="formData.fileList" tooltip-effect="dark" style="width: 100%;"
            :key="tableKey">
            <el-table-column type="selection" :width="columnWidth[0]"></el-table-column>

            <el-table-column label="名称" :width="columnWidth[1]">
              <template slot-scope="scope">
                <div class="name-wrap">
                  <div class="name-txt">{{ scope.row.name }}</div>
                  <div class="split-mode">
                    <div class="split-txt">拆分方式</div>
                    <el-dropdown @command="handleCommand($event, scope.row)">
                      <span class="el-dropdown-link">
                        {{ scope.row.splitTypeTxt }}<i class="el-icon-caret-bottom el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="1">最大页数</el-dropdown-item>
                        <el-dropdown-item command="2">选择范围</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="页数" :width="columnWidth[2]">
              <template slot-scope="scope">
                <div class="name-wrap">
                  <div>{{ scope.row.page }}</div>
                  <div class="split-mode page-wrap">
                    <div v-if="scope.row.splitType == '1'">
                      <el-form-item :prop="`fileList.${scope.$index}.stepLimit`" :rules="[{
                        ...rules.stepLimit[0],
                        row: scope.row,
                      }]">
                        每<el-input class="splittype-input input-small" v-model="scope.row.stepLimit" placeholder=""
                          type="tel">
                        </el-input>页保存为一份文档
                      </el-form-item>
                    </div>
                    <div v-else>
                      <el-form-item :prop="`fileList.${scope.$index}.stepLimit`" :rules="[{
                        ...rules.stepLimit[1],
                        row: scope.row,
                      }]">
                        <el-input class="input-larger" v-model="scope.row.stepLimit" placeholder="示例：1-2,3-5,6-8"
                          type="tel">
                        </el-input>
                      </el-form-item>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="输出范围" :width="columnWidth[3]">
              <template slot-scope="scope">
                <div class="range-wrap">
                  <el-form-item :prop="`fileList.${scope.$index}.rangeStart`" :rules="[{
                    ...rules.rangeStart,
                    row: scope.row,
                  }]">
                    <el-input class="input-small" v-model="scope.row.rangeStart" placeholder="" type="tel"></el-input>
                  </el-form-item>
                  <div class="input-split"></div>

                  <el-form-item :prop="`fileList.${scope.$index}.rangeEnd`" :rules="[{
                    ...rules.rangeEnd,
                    row: scope.row,
                  }]">
                    <el-input class="input-small" v-model="scope.row.rangeEnd" placeholder="" type="tel"></el-input>
                  </el-form-item>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="操作" :width="columnWidth[4]">
              <template slot-scope="scope">
                <el-button @click.native.prevent="deleteRow(scope.$index, fileList)" type="text" size="small">
                  <div class="icon-delete">
                    <i class="el-icon-delete"></i>
                  </div>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>

      <UploadTip :show="fileList.length == 0" :activeTab="activeTab"></UploadTip>

      <div class="btn-ocr" :class="{ 'btn-ocr-active': fileList.length > 0 && !ocrInProgress }"
        @click.stop="handleStartOCR">
        {{ btnTxt }}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Upload from '../upload';
import UploadTip from './../common/UploadTip';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { downloadFile } from '@/utils/download.js'
import { getOssAuth, uploadFile } from '@/utils/ossUpload';
import TaskProgress from '@/utils/taskProgress';
import { isLoggedIn, isNoDayUseCount } from '@/utils/authUtils';

const MAX_IMAGE = 10000;

export default {
  components: {
    Upload,
    UploadTip
  },
  name: 'UploadArea',
  props: {
    activeTab: {
      type: String,
      required: true,
    },
    tabName: String,
  },
  data() {
    return {
      optType: 1,
      ocrSuccess: false,
      ocrInProgress: false,
      btnTxt: "PDF拆分",
      dialogVisible: false,
      limitCount: MAX_IMAGE,
      showFileList: true,
      fileList: [],
      files: [],
      formData: { fileList: [] },
      selectAll: false,
      options: [{
        value: '1',
        label: '最大页数'
      }, {
        value: '2',
        label: '选择范围'
      }],
      value: '1',
      rules: {
        rangeStart: {
          validator: (rule, value, callback) => {
            console.log("validateRangeStart", rule.row)
            const { rangeEnd, page } = rule.row;
            if (value < 1 || value > page || value > rangeEnd) {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: 'blur',
        },
        rangeEnd: {
          validator: (rule, value, callback) => {
            console.log("validateRangeEnd", rule.row, value)
            const { rangeStart, page } = rule.row;
            if (value < rangeStart || value < 1 || value > page) {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: 'blur',
        },
        stepLimit: [{
          validator: (rule, value, callback) => {
            console.log("validateStepLimit", rule.row)
            const { page } = rule.row;
            if (!value || value < 1 || value > page) {
              callback(new Error(" "));
            } else {
              callback();
            }
          },
          trigger: 'blur',
        }, {
          validator: (rule, value, callback) => {
            console.log("validateStepLimit2", rule.row)
            const { page } = rule.row;
            // 先替换中文逗号为英文逗号
            const normalizedValue = value.replace(/，/g, ',');
            const ranges = normalizedValue.split(',');

            let isValid = true;
            let errorMsg = '';

            // 检查每个范围
            for (let range of ranges) {
              const [start, end] = range.split('-').map(Number);
              if (isNaN(start) || isNaN(end) || start < 1 || end < 1 || start > end || end > page) {
                isValid = false;
                errorMsg = `格式错误：每个范围的起始值和结束值应为数字且在 1 到 ${page} 之间。`;
                console.log(errorMsg);
                break;
              }
            }

            if (!isValid) {
              callback(new Error(" "));
            } else {
              callback();
            }
          }
        }]
      }, // 表单规则
      columnWidth: [120, 500, 400, 400, 120],
      baseWidth: 1920, // 默认浏览器宽度
      baseColWidth: [120, 500, 400, 400, 120], // 默认列宽
      tableKey: 0, // 用于强制表格刷新
    };
  },
  mounted() {
    // this.fileList = Vue.observable([]);
    this.formData.fileList = this.fileList;

    window.addEventListener('resize', this.updateTableWidth);
    this.updateTableWidth();
  },
  beforeDestroy() {
    // 移除事件监听器
    window.removeEventListener('resize', this.updateTableWidth);
  },
  methods: {
    updateTableWidth() {
      const currentWidth = window.innerWidth; // 当前窗口宽度
      const scale = currentWidth / this.baseWidth; // 缩放比例
      for (let i = 0; i < this.baseColWidth.length; i++) {
        this.columnWidth[i] = Math.floor(this.baseColWidth[i] * scale); // 动态计算列宽
      }

      console.log("updateTableWidth", currentWidth, scale, this.columnWidth)
      this.refreshTable();
    },
    refreshTable() {
      console.log("refreshTable")
      this.tableKey += 1; // 修改 key，强制重新渲染表格
    },
    checkUserVip() {
      if (!isLoggedIn(this.$cookies)) {
        console.log("未登录");
        this.$emit('notify', { message: '未登录', type: 'login' });
        return false;
      }

      if (isNoDayUseCount(this.$H.userInfo)) {
        console.log("升级会员");
        this.$emit('notify', { message: '升级会员', type: 'purchase' });
        return false;
      }

      return true;
    },
    handleStartOCR() {
      if (!this.checkUserVip()) {
        return;
      }

      if (this.fileList.length == 0) {
        return;
      }

      const selectedRows = this.$refs.multipleTable.selection;
      console.log("handleStartOCR", selectedRows);
      if (!this.checkPageRange(selectedRows)) {
        this.$message.error('请检查输出范围是否正确');
        return;
      }

      if (!this.checkStepLimit(selectedRows)) {
        return;
      }

      this.uploadToOss(selectedRows);
    },
    checkPageRange(fileList) {
      return fileList.every((file) => {
        const { page, rangeStart, rangeEnd } = file;
        return (
          rangeStart >= 1 &&
          rangeEnd >= 1 &&
          rangeStart <= page &&
          rangeEnd <= page &&
          rangeStart <= rangeEnd
        );
      });
    },
    checkStepLimit(fileList) {
      return fileList.every((file) => {
        const { page, splitType, stepLimit } = file;
        if (splitType == 1) {
          if (stepLimit >= 1 && stepLimit <= page) {
            return true;
          } else {
            this.$message.error('请检查最大页数范围是否正确');
          }
        } else if (splitType == 2) {
          return this.isValidStepLimit(stepLimit, page);
        }
      });
    },
    isValidStepLimit(stepLimit, maxPage) {
      // 替换中文逗号为英文逗号
      stepLimit = stepLimit.replace(/，/g, ',');
      // 正则表达式：检查格式是否符合 "1-2,3-6,6-8"
      const rangePattern = /^(\d+)-(\d+)(,\d+-\d+)*$/;

      if (!rangePattern.test(stepLimit)) {
        this.$message.error("格式不正确。格式应为 '1-2,3-6,6-8' 这样的形式");
        return false;
      }

      // 分割各个范围并进行验证
      const ranges = stepLimit.split(',');

      for (let range of ranges) {
        const [start, end] = range.split('-').map(Number);

        // 检查范围的起始和结束是否有效
        if (start < 1 || end < 1 || start > maxPage || end > maxPage || start > end) {
          this.$message.error(`无效的范围：${range}，范围应在 1 和 ${maxPage} 之间，并且起始数字不大于结束数字`);
          return false;
        }
      }

      return true;
    },
    handleChange(file, fileList) {
      console.log("handleChange file", file);
      console.log("handleChange", fileList);
      if (file && file.raw && file.raw.type !== 'application/pdf') {
        this.$message.error('请选择PDF文件');
        this.fileList = [];
        return false;
      }
      
      if (file && file.size && file.size > 50 * 1024 * 1024) {//52428800
        this.$message.error('单个文件仅支持50M以内，请重新上传。');
        this.fileList = [];
        return false;
      }

      this.pdfPageCount(file, fileList);
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
      this.formData.fileList = [...this.fileList];
    },
    initOcr() {
      this.limitCount = MAX_IMAGE;
      this.ocrSuccess = false;
      this.ocrInProgress = false;
      this.fileList = [];
      this.btnTxt = "开始识别";
    },
    handleResetOCR() {
      this.initOcr();
      this.$nextTick(() => {
        this.radio = '正序';
      });
    },
    async uploadToOss(fileList) {
      console.log("uploadToOss fileList", fileList);
      if (fileList.length < 1) {
        this.$message.warning('请选择需要拆分的文件');
        return;
      }

      this.showLoading();

      try {
        const ossAuth = await getOssAuth.call(this); // 调用封装的获取授权方法，确保上下文是 Vue 实例

        // 定义上传结果存储数组
        const uploadResults = await Promise.all(
          fileList.map(async (file) => {
            try {
              let uid = this.$H.userInfo.uid;
              if (uid == undefined) {
                uid = "0000";
              }
              const result = await uploadFile(ossAuth, file, ".pdf", uid);
              return { success: true, file, result }; // 成功标志
            } catch (error) {
              console.error(`文件上传失败: ${file.name}`, error);
              return { success: false, file, error }; // 失败标志
            }
          })
        );

        // 处理上传结果
        const successFiles = uploadResults.filter(item => item.success); // 成功的文件
        const failedFiles = uploadResults.filter(item => !item.success); // 失败的文件

        console.log('上传成功的文件:', successFiles);
        console.log('上传失败的文件:', failedFiles);

        if (failedFiles.length > 0) {
          this.$message.warning(`${failedFiles.length} 个文件上传失败，请重试`);
          return;
        }

        if (successFiles.length > 0) {
          const successUnploadFiles = successFiles.filter(item => item.file.fileUrl);
          if (successUnploadFiles.length > 0) {
            const files = successUnploadFiles.map(item => ({
              fileUrl: item.file.fileUrl,
              rangeLimit: `${item.file.rangeStart}-${item.file.rangeEnd}`,
              splitType: item.file.splitType,
              stepLimit: item.file.stepLimit.replace(/，/g, ','),
            }));

            this.startPdfSplit(files);
          } else {
            this.hideLoading();
            this.$message.error('上传失败');
          }
        } else {
          this.hideLoading();
          this.$message.error('上传失败');
        }
      } catch (error) {
        this.hideLoading();
        this.$message.error('上传过程中发生错误:' + error);
        console.error('上传过程中发生错误:', error);
      }
    },
    async startPdfSplit(files) {
      let res = await this.$api.pdfSplit({ files: files, optType: this.optType });
      console.log("startPdfMerger", res)
      if (res.data.code === 0) {
        this.$emit('notify', { message: '更新用户信息', type: 'userinfo' });

        await this.startTaskProgressCheck(res.data.mainTaskId);
        this.downloadFile();
        this.hideLoading();
      } else if (res.data.code === 401) {
        this.$emit('notify', { message: '未登录', type: 'login' });
        this.hideLoading();
      } else if (res.data.code === 600) {
        this.$emit('notify', { message: '升级会员', type: 'purchase' });
        this.hideLoading();
      } else {
        this.$message.error(res.data.msg);
        this.hideLoading();
      }
    },
    async startTaskProgressCheck(mainTaskId) {
      //下载resultUrl存在fileList[0]中
      const taskProgress = new TaskProgress(
        this.$api,
        this.fileList
      );

      try {
        await taskProgress.startProgressCheck(mainTaskId);
        console.log('Task completed successfully');
      } catch (error) {
        console.error('Task failed:', error);
      }
    },
    downloadFile() {
      console.log("downloadFile", this.fileList)
      this.fileList.map((file) => {
        if (file.errorCode !== 'OK') {
          this.$message.error(`${file.name}拆分失败`);
          return;
        }
        if (file.resultUrl) {
          let resultUrl = file.resultUrl;
          const fileName = resultUrl.substring(resultUrl.lastIndexOf('/') + 1);
          console.log(fileName);
          downloadFile(resultUrl, fileName);
        } else {
          this.$message.error('PDF拆分失败');
        }
      });
    },
    async pdfPageCount(file, fileList) {
      console.log("pdfPageCount", file)
      if (file && file.raw.type === 'application/pdf') {
        this.showLoading();
        const fileReader = new FileReader();

        fileReader.onload = async (e) => {
          const typedArray = new Uint8Array(e.target.result);
          // 设置 PDF.js worker
          pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
          // 加载 PDF 文件
          const pdfDoc = await pdfjsLib.getDocument(typedArray).promise;
          console.log("pdf2Images", pdfDoc)
          Vue.set(file, 'page', pdfDoc.numPages);
          Vue.set(file, 'rangeStart', 1);
          Vue.set(file, 'rangeEnd', pdfDoc.numPages);
          Vue.set(file, 'splitTypeTxt', '最大页数');
          Vue.set(file, 'splitType', 1);
          Vue.set(file, 'stepLimit', '');

          console.log("pdf2Images fileList", this.fileList)
          this.fileList.push(file);
          this.formData.fileList = [...this.fileList]; // 确保引用变动，触发重新渲染

          this.hideLoading();
        };

        fileReader.readAsArrayBuffer(file.raw);
      }
    },
    showLoading(text) {
      this.fullscreenLoading = this.$loading({
        lock: true,
        text: text,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    hideLoading() {
      if (this.fullscreenLoading)
        this.fullscreenLoading.close();
    },
    handleSelectAll(val) {
      this.fileList.forEach((item) => {
        item.checked = val;
      });
    },
    handleRowChange(row) {
      this.selectAll = this.fileList.every((item) => item.checked);
    },
    handleCommand(command, row) {
      console.log(command, row);
      row.splitType = command;
      row.splitTypeTxt = command === '1' ? '最大页数' : '选择范围';
      if (command === '2') {
        row.stepLimit = '';
      }
    }
  }
}
</script>

<style scoped>
@import '@/assets/css/uploadarea.css';

.name-wrap {
  display: flex;
  flex-direction: column;
}

.split-mode {
  margin-top: 24px;
  display: flex;
  align-items: center;
}

.split-txt {
  margin-right: 20px;
}

.splittype-input {
  margin: 0 4px;
}

.el-dropdown-link {
  cursor: pointer;
  color: #0769F6;
}

.el-icon-caret-bottom {
  font-size: 18px;
}

.input-larger {
    z-index: 10;
      width: 285px;
      height: 30px;
}

/deep/ .el-input .el-input__inner {
  border-radius: 0;
  text-align: center;
  padding: 0 5px;
  height: 100%;
}

/deep/ .el-table th.el-table__cell>.cell {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  font-style: normal;
}

/deep/ .el-table td.el-table__cell div {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  font-style: normal;
}

/deep/ .el-icon-delete {
  font-size: 22px;
  color: #2669F3;
}
</style>