export default class TaskProgress {
  constructor(api, fileList) {
    this.api = api; // the API instance to interact with the backend
    this.fileList = fileList; // file list for progress tracking
    this.mainTaskIdList = []; // to store main task ids and their respective intervals
  }

  async startProgressCheck(mainTaskId) {
    return new Promise((resolve, reject) => {
      const intervalId = setInterval(async () => {
        try {
          const allFinished = await this.checkProgress(mainTaskId, intervalId);
          console.log('All tasks finished:', allFinished);
          if (allFinished) {
            resolve(true); // Resolve the promise when the task is complete
          }
        } catch (error) {
          this.stopInterval(intervalId);
          reject(error); // Reject the promise if an error occurs
        }
      }, 500);

      this.mainTaskIdList.push({ mainTaskId, intervalId });
    });
  }

  async checkProgress(mainTaskId, intervalId) {
    try {
      const data = await this.getFileProgress(mainTaskId);

      let allFinish = true;
      data.taskInfo.files.map(item => {
        if (item.optType == 2 || item.optType == 1 || item.optType == 3) {//pdf合并、pdf拆分、PDF压缩
          if (item.resultUrl === '' && item.errorCode === '') {
            allFinish = false;
          } else {
            let file = this.fileList.find((fileItem) => item.fileUrl.includes(fileItem.fileUrl));
            if (file) {
              file.resultUrl = item.resultUrl;
              file.errorCode = item.errorCode;
            }
          }
          
          return;
        }

        if (item.errorCode === '') {
          allFinish = false;
        }
        let file = this.fileList.find(fileItem => fileItem.fileUrl === item.fileUrl);
        if (file) {
          file.resultUrl = item.resultUrl;
          file.progress = item.progress;
          file.errorCode = item.errorCode;
          file.ocrSuccess = item.errorCode !== '';
          file.ocrInProgress = !file.ocrSuccess;
          file.mainTaskId = mainTaskId;
          this.updateFileList(file);
        } else {
          console.log("checkProgress 没有找到对应图片");
        }
      });

      if (allFinish) {
        console.log('Progress is 100%. Stopping timer.');
        this.stopInterval(intervalId);
        return true;
      }

      return false;
    } catch (error) {
      console.error('Error checking progress:', error);
      throw error;
    }
  }

  updateFileList(file) {
    // Update the file list to reflect progress and state changes
    const fileIndex = this.fileList.findIndex(fileItem => fileItem.fileUrl === file.fileUrl);
    if (fileIndex >= 0) {
      this.fileList.splice(fileIndex, 1, file); // Replace the file in the list
    }
  }

  async getFileProgress(mainTaskId) {
    const res = await this.api.getfileProgress({ mainTaskId });
    if (res.data.code === 0) {
      return res.data;
    }
    throw new Error('Error fetching file progress');
  }

  stopInterval(intervalId) {
    clearInterval(intervalId); // Stop the timer
    this.mainTaskIdList = this.mainTaskIdList.filter(item => item.intervalId !== intervalId); // Remove the interval
  }
}