<template>
  <div class="upload-area">
    <div class="upload-demo upload-wrap">
      <Upload ref="uploadRef" class="upload-demo" drag multiple list-type="picture" :file-list="fileList"
        :auto-upload="false" action="" :on-change="handleChange" :limit="limitCount" :show-file-list="false"
        accept="application/pdf" :activeTab="activeTab">
      </Upload>

      <div v-show="formData.fileList.length>0" class="table-wrap">
        <el-form ref="fileListForm" :model="formData" label-width="0px" style="width: 100%;">
          <el-table ref="multipleTable" :data="formData.fileList" tooltip-effect="dark" style="width: 100%;"
            :key="tableKey">
            <el-table-column type="selection" :width="columnWidth[0]"></el-table-column>

            <el-table-column label="名称" :width="columnWidth[1]">
              <template slot-scope="scope">
                <div class="name-txt"> {{ scope.row.name }} </div>
              </template>
            </el-table-column>
            <el-table-column label="压缩效果" :width="columnWidth[2]">
              <template slot-scope="scope">
                <el-dropdown @command="handleCommand($event, scope.row)">
                  <span class="el-dropdown-link">
                    {{ compressRateList[scope.row.compressRate] }}<i class="el-icon-caret-bottom el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="80">{{ compressRateList['80'] }}</el-dropdown-item>
                    <el-dropdown-item command="60">{{ compressRateList['60'] }}</el-dropdown-item>
                    <el-dropdown-item command="30">{{ compressRateList['30'] }}</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
            </el-table-column>
            <el-table-column label="原文档大小" :width="columnWidth[3]">
              <template slot-scope="scope">{{ scope.row.fileSizeMB }} MB</template>
            </el-table-column>
            <el-table-column label="压缩后大小" :width="columnWidth[4]">
              <template v-if="scope.row.fileSizeZipMB" slot-scope="scope">{{ scope.row.fileSizeZipMB }} MB</template>
            </el-table-column>
            <el-table-column label="操作" :width="columnWidth[5]">
              <template slot-scope="scope">
                <el-button @click.native.prevent="deleteRow(scope.$index, fileList)" type="text" size="small">
                  <div class="icon-delete">
                    <i class="el-icon-delete"></i>
                  </div>
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </div>

      <UploadTip :show="fileList.length == 0" :activeTab="activeTab"></UploadTip>

      <div class="btn-ocr" :class="{ 'btn-ocr-active': fileList.length > 0 && !ocrInProgress }"
        @click.stop="handleStartOCR">{{ btnTxt }}
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Upload from '../upload';
import UploadTip from './../common/UploadTip';
import { downloadFile } from '@/utils/download.js'
import { getOssAuth, uploadFile } from '@/utils/ossUpload';
import TaskProgress from '@/utils/taskProgress';
import { isLoggedIn, isNoDayUseCount } from '@/utils/authUtils';

const MAX_IMAGE = 10000;

export default {
  components: {
    Upload,
    UploadTip
  },
  name: 'UploadArea',
  props: {
    activeTab: {
      type: String,
      required: true,
    },
    tabName: String,
  },
  data() {
    return {
      optType: 3,
      ocrSuccess: false,
      ocrInProgress: false,
      btnTxt: "开始压缩",
      dialogVisible: false,
      limitCount: MAX_IMAGE,
      showFileList: true,
      fileList: [],
      files:[],
      rules: {}, // 表单规则
      formData: { fileList: []},
      selectAll: false,
      compressRateList: {
        "80": "高品质",
        "60": "标准品质",
        "30": "低品质",
      },
      columnWidth: [120, 500, 300, 250, 250, 120],
      baseWidth: 1920, // 默认浏览器宽度
      baseColWidth: [120, 500, 300, 250, 250, 120], // 默认列宽
      tableKey: 0, // 用于强制表格刷新
    };
  },
  mounted() {
    // this.fileList = Vue.observable([]);
    this.formData.fileList = this.fileList;

    window.addEventListener('resize', this.updateTableWidth);
    this.updateTableWidth();
  },
  beforeDestroy() {
    // 移除事件监听器
    window.removeEventListener('resize', this.updateTableWidth);
  },
  methods: {
    updateTableWidth() {
      const currentWidth = window.innerWidth; // 当前窗口宽度
      const scale = currentWidth / this.baseWidth; // 缩放比例
      for (let i = 0; i < this.baseColWidth.length; i++) {
        this.columnWidth[i] = Math.floor(this.baseColWidth[i] * scale); // 动态计算列宽
      }

      console.log("updateTableWidth", currentWidth, scale, this.columnWidth)
      this.refreshTable();
    },
    refreshTable() {
      console.log("refreshTable")
      this.tableKey += 1; // 修改 key，强制重新渲染表格
    },
    checkUserVip() {
      if (!isLoggedIn(this.$cookies)) {
        console.log("未登录");
        this.$emit('notify', { message: '未登录', type: 'login' });
        return false;
      }

      if (isNoDayUseCount(this.$H.userInfo)) {
        console.log("升级会员");
        this.$emit('notify', { message: '升级会员', type: 'purchase' });
        return false;
      }  

      return true;
    },
    handleStartOCR() {
      if (!this.checkUserVip()) {
        return;
      }

      if (this.fileList.length == 0) {
        return;
      }

      const selectedRows = this.$refs.multipleTable.selection;
      console.log("handleStartOCR", selectedRows);
      this.uploadToOss(selectedRows);
    },
     handleChange(file, fileList) {
      console.log("handleChange file", file);
      console.log("handleChange", fileList);
      if (file && file.raw && file.raw.type !== 'application/pdf') {
        this.$message.error('请选择PDF文件');
        this.fileList = [];
        return false;
      }

       if (file && file.size && file.size > 50 * 1024 * 1024) {//52428800
         this.$message.error('单个文件仅支持50M以内，请重新上传。');
         this.fileList = [];
         return false;
       }

      this.pdfFileSize(file, fileList);
    },
    deleteRow(index, rows) {
      rows.splice(index, 1);
      this.formData.fileList = [...this.fileList];
    },
    async uploadToOss(fileList) {
      console.log("uploadToOss fileList", fileList);
      if (fileList.length < 1) {
        this.$message.warning('请选择需要压缩的文件');
        return;
      }

      this.showLoading();

      try {
        const ossAuth = await getOssAuth.call(this); // 调用封装的获取授权方法，确保上下文是 Vue 实例

        // 定义上传结果存储数组
        const uploadResults = await Promise.all(
          fileList.map(async (file) => {
            try {
              let uid = this.$H.userInfo.uid;
              if (uid == undefined) {
                uid = "0000";
              }
              const result = await uploadFile(ossAuth, file, ".pdf", uid);
              return { success: true, file, result }; // 成功标志
            } catch (error) {
              console.error(`文件上传失败: ${file.name}`, error);
              return { success: false, file, error }; // 失败标志
            }
          })
        );

        // 处理上传结果
        const successFiles = uploadResults.filter(item => item.success); // 成功的文件
        const failedFiles = uploadResults.filter(item => !item.success); // 失败的文件

        console.log('上传成功的文件:', successFiles);
        console.log('上传失败的文件:', failedFiles);

        if (failedFiles.length > 0) {
          this.$message.warning(`${failedFiles.length} 个文件上传失败，请重试`);
          return;
        }

        if (successFiles.length > 0) {
          const successUnploadFiles = successFiles.filter(item => item.file.fileUrl);
          if (successUnploadFiles.length > 0) {
            const files = successFiles.map(item => ({
              fileUrl: item.file.fileUrl,
              compressRate: item.file.compressRate,
            }));

            this.startPdfCompress(files);
          } else {
            this.hideLoading();
            this.$message.error('上传失败');
          }
        } else {
          this.hideLoading();
          this.$message.error('上传失败');
        }
      } catch (error) {
        this.hideLoading();
        this.$message.error('上传过程中发生错误:' + error);
        console.error('上传过程中发生错误:', error);
      }
    },
    async startPdfCompress(files) {
      let res = await this.$api.pdfCompress({ files: files, optType: this.optType });
      console.log("startPdfCompress", res)
      if (res.data.code === 0) {
        this.$emit('notify', { message: '更新用户信息', type: 'userinfo' });

        await this.startTaskProgressCheck(res.data.mainTaskId);
        this.downloadFile();
        this.hideLoading();
      } else if (res.data.code === 401) {
        this.$emit('notify', { message: '未登录', type: 'login' });
        this.hideLoading();
      } else if (res.data.code === 600) {
        this.$emit('notify', { message: '升级会员', type: 'purchase' });
        this.hideLoading();
      } else {
        this.$message.error(res.data.msg);
        this.hideLoading();
      }
    },
    async startTaskProgressCheck(mainTaskId) {
      //下载resultUrl存在fileList[0]中
      const taskProgress = new TaskProgress(
        this.$api,
        this.fileList
      );

      try {
        await taskProgress.startProgressCheck(mainTaskId);
        console.log('Task completed successfully');
      } catch (error) {
        console.error('Task failed:', error);
      }
    },
    downloadFile() {
      this.fileList.map((file) => {
        if (file.errorCode !== 'OK') {
          this.$message.error(`${file.name}压缩失败`);
          return;
        }
        if (file.resultUrl) {
          let resultUrl = file.resultUrl;
          const fileName = resultUrl.substring(resultUrl.lastIndexOf('/') + 1);
          console.log(fileName);
          downloadFile(resultUrl, fileName);
          this.getPdfSize(file, resultUrl);
        } else {
          this.$message.error('PDF压缩失败');
        }
      });
    },
    async getPdfSize(file, pdfUrl) {
      try {
        if (!pdfUrl) {
          console.log("请输入有效的PDF URL地址！");
          return;
        }

        const response = await fetch(pdfUrl, { method: "HEAD" }); // 只请求HEAD信息
        const contentLength = response.headers.get("Content-Length");

        let fileSizeZipMB;
        if (contentLength) {
          const fileSizeBytes = parseInt(contentLength, 10);
          fileSizeZipMB = fileSizeBytes / (1024 * 1024); // 转换为MB
          fileSizeZipMB = fileSizeZipMB.toFixed(2);
        } else {
          console.log("无法获取文件大小，可能是服务器未返回Content-Length头信息。");
          fileSizeZipMB = "NaN";
        }

        Vue.set(file, 'fileSizeZipMB', fileSizeZipMB);
      } catch (error) {
        console.error("获取PDF大小时出错：", error);
      }
    },
    async pdfFileSize(file, fileList) {
      console.log("pdfFileSize", file)
      if (file && file.raw.type === 'application/pdf') {
        this.showLoading();

        let fileSizeMB = file.size / (1024 * 1024);
        fileSizeMB = fileSizeMB.toFixed(2);
        Vue.set(file, 'fileSizeMB', fileSizeMB);
        Vue.set(file, 'compressRate', 60);

        this.fileList.push(file);
        this.formData.fileList = [...this.fileList]; // 确保引用变动，触发重新渲染
        
        this.hideLoading();
      }
    },
    showLoading(text) {
      this.fullscreenLoading = this.$loading({
        lock: true,
        text: text,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    hideLoading() {
      if (this.fullscreenLoading)
        this.fullscreenLoading.close();
    },
    handleSelectAll(val) {
      this.fileList.forEach((item) => {
        item.checked = val;
      });
    },
    handleRowChange(row) {
      this.selectAll = this.fileList.every((item) => item.checked);
    },
    handleCommand(command, row) {
      console.log(command, row);
      row.compressRate = command;
    }
  }
}
</script>

<style scoped>
@import '@/assets/css/uploadarea.css';

/deep/ .el-table th.el-table__cell>.cell {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  font-style: normal;
}

/deep/ .el-table td.el-table__cell div {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
  line-height: 22px;
  font-style: normal;
}

/deep/ .el-icon-delete {
  font-size: 22px;
  color: #2669F3;
}
</style>