<template>
  <div class="sidebar">
    <div v-if="announcements.length > 0" class="announcement-wrap" @click="handleAnnouncement">
      <el-carousel height="50px" autoplay arrow="never" :interval="10000" indicator-position="none"
        @change="changeAnnouncement">
        <el-carousel-item v-for="(item, index) in announcements" :key="index">
          <div class="announcement-text">公告: {{ item.content }}</div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="logo-wrap">
      <img class="logo" src="../assets/img/logotxt.png" alt="">

      <div class="logo-text">万能文档转换器</div>
    </div>

    <div class="login-wrap">
      <div v-if="!logined" class="login-text" @click="handleLogin">点击登录</div>
      <div v-else class="userinfo-wrap">
        <div class="userlevel" :class="{ 'userlevel1': userInfo.vipLevel == 1 }" @click="handlePurchaseVip">
          <img class="userlevel-img"
            :src="userInfo.vipLevel == 0 ? require('@/assets/img/vip0.png') : require('@/assets/img/vip1.png')"
            alt="VIP">

          <div class="userlevel-upgrade">
            <div v-if="userInfo.vipLevel == 0" class="upgrade">立即升级</div>
            <div v-else class="renewal">续费</div>

            <div class="vipfree">新年促销</div>
          </div>
        </div>
      </div>
    </div>

    <SidebarTabs @tab-selected="handleTabClick"></SidebarTabs>

    <UserLogin ref="userLogin" v-if="userLoginVisible" @register="handleUserRegister" @success="loginSuccess">
    </UserLogin>
    <UserRegister ref="userRegister" v-if="userRegisterVisible" @login="handleLogin" @success="loginSuccess">
    </UserRegister>
    <PurchaseVip ref="purchaseVip" v-if="purchaseVipVisible" @logout="handleLogout" @purchase="loginSuccess">
    </PurchaseVip>
    <TipLogin ref="tipLogin" v-if="tipLoginVisible" @login="handleLogin" @register="handleUserRegister"></TipLogin>
    <TipPurchase ref="tipPurchase" v-if="tipPurchaseVisible" @purchase="handlePurchaseVip"></TipPurchase>
    <Announcement ref="announcement" v-if="announcementVisible"></Announcement>
    <AboutUs ref="aboutUs" v-if="aboutUsVisible"></AboutUs>
  </div>
</template>

<script>
import UserLogin from './UserLogin.vue';
import UserRegister from './UserRegister.vue'
import PurchaseVip from './PurchaseVip.vue';
import SidebarTabs from './SidebarTabs.vue';
import TipLogin from  './TipLogin.vue';
import TipPurchase from './TipPurchase.vue';
import Announcement from './Announcement.vue';
import AboutUs from './AboutUs.vue';

export default {
  name: 'SideBar',
  components: {
    UserLogin,
    UserRegister,
    PurchaseVip,
    SidebarTabs,
    TipLogin,
    TipPurchase,
    Announcement,
    AboutUs
  },
  props: {
    activeTab: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      userInfo: {
        account: '',
        vipLevel: 0,
        dayUseCount: 0,
        vipEndTime: '',
        vipEndTimeFormat: '',
        inviteCode: '',
      },
      dayUseCount: 0,
      logined: false,
      userLoginVisible: false,
      userRegisterVisible: false,
      purchaseVipVisible: false,
      tipLoginVisible: false,
      tipPurchaseVisible: false,
      aboutUsVisible: false,
      firstCreated: false,
      announcements: [
      //   {
      //   content: "欢迎使用万能文档转换器，请及时关注公告。"
      // }, {
      //   content: "公告一：系统维护，预计持续到晚上10点"
      //   }, {
      //   content: "公告二：请及时更新资料，以免影响使用。"
      //   }
      ],
      announcementVisible: false,
      curAnnouncementIndex: 0,
    };
  },
  watch: {
    activeTab(val) {
      // console.log(val);
      this.$H.activeTab = val;
      this.setDayUseCount(val);
    }
  },
  created(options) {
    // console.log("token", this.$cookies.get('token'));
    if (this.$cookies.get('token')) {
      this.logined = true;
      this.getUserInfo();
    } else {
      this.addPV(0);
    }

    this.getNoticeList();
  },
  methods: {
    handleTabClick(tab) {
      this.$emit('tab-selected', tab);
    },
    handleLogin() {
      console.log("handleLogin")
      this.userLoginVisible = true
      this.$nextTick(() => {
        this.$refs.userLogin.init();
      })
    },
    handleUserRegister() {
      console.log("handleUserRegister")
      this.userRegisterVisible = true
      this.$nextTick(() => {
        this.$refs.userRegister.init();
      })
    },
    handlePurchaseVip() {
      console.log("handlePurchaseVip")
      this.purchaseVipVisible = true
      this.$nextTick(() => {
        this.$refs.purchaseVip.init(this.userInfo);
      })
    },
    
    handleLogout() {
      console.log("handleLogout");
      this.logined = false;
    },
    handleAnnouncement() {
      console.log("handleAnnouncement");
      let text = this.announcements[this.curAnnouncementIndex].content;
      text = text ? text : '';
      this.announcementVisible = true;
      this.$nextTick(() => {
        this.$refs.announcement.init(text);
      });
    },
    changeAnnouncement(e) {
      console.log("changeAnnouncement", e);
      this.curAnnouncementIndex = e;
    },
    showTipLogin() {
      this.tipLoginVisible = true;
      this.$nextTick(() => {
        this.$refs.tipLogin.init();
      })
    },
    showTipPurchase() {
      this.tipPurchaseVisible = true;
      this.$nextTick(() => {
        this.$refs.tipPurchase.init();
      })
    },
    /*
    "info": {
      "uid": 2,
      "lastLoginTime": "2024-09-19 11:53:17",
      "vipLevel": 0
    }*/
    loginSuccess(info) {
      this.logined = true;

      this.getUserInfo();
    },
    async getUserInfo() {
      let res = await this.$api.getUserInfo();
      if (res.data.code == 0) {
        this.userInfo = res.data.info;

        if (this.userInfo.vipEndTime) {
          const vipEndTime = this.userInfo.vipEndTime;
          this.userInfo.vipEndTimeFormat = vipEndTime.split(' ')[0];
        }
        this.$H.userInfo = res.data.info;
        this.setDayUseCount(this.activeTab);

        console.log("getUserInfo res");
        if (!this.firstCreated) {
          this.firstCreated = true;
          this.addPV(this.userInfo.uid);
        }
      } else if (res.data.code === 401) {
        this.logined = false;
        this.showTipLogin();
        this.addPV(0);
      }
    },
    setDayUseCount(activeTab) {
      console.log("setDayUseCount", activeTab);
      if (activeTab == 'PdfMerger' || activeTab == 'PdfSplit' || activeTab == 'PdfZip' || activeTab == 'ImgZip') {
        this.dayUseCount = this.getFreeCountByTab(activeTab, this.$H.userInfo.optFreeList)
      } else {
        this.dayUseCount = this.$H.userInfo.dayUseCount;
      }
    },
    getFreeCountByTab(activeTab, optFreeList) {
      let optType;

      // Determine the corresponding optType based on the activeTab value
      switch (activeTab) {
        case 'PdfSplit':
          optType = 1;  // PDF拆分
          break;
        case 'PdfMerger':
          optType = 2;  // PDF合并
          break;
        case 'PdfZip':
          optType = 3;  // PDF压缩
          break;
        case 'ImgZip':
          optType = 5;  // 图片压缩
          break;
        default:
          return null;  // If the activeTab doesn't match any case
      }

      // Find the corresponding object in the optFreelist
      const opt = optFreeList.find(item => item.optType === optType);

      // Return the freeCount value, or null if no matching optType is found
      return opt ? opt.freeCount : 0;
    },
    async addPV(uid) {
      this.firstCreated = true;
      const urlParams = new URLSearchParams(window.location.search);
      let channel = urlParams.get('channel') || 1;
      let res = await this.$api.addPV({uid: uid, channel: channel});
      if (res.data.code == 0) {
        // console.log("addPV", res.data);
      }
    },
    async getNoticeList() {
      let res = await this.$api.getNoticeList({page: 1, limit: 1});
      if (res.data.code == 0) {
        this.announcements = res.data.page.list;
      }
    },
    
  },
}
</script>

<style scoped>
.sidebar {
  position: relative;
  width: 176px;
  background-color: #FFFFFF;
  padding: 0 22px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  margin: 20px 0;
}

.sidebar ul li a {
  text-decoration: none;
  color: #333;
  height: 40px;
}

.sidebar a {
  height: 40px;
}

.sidebar a.is-active {
  background: #0769F6;
  color: #fff;
  padding: 10px 40px;
}

.sidebar a.is-active:before {
  content: " ";
  position: absolute;
  left: 100%;
  width: 0;
  height: 0;
  border-width: 1.3vh 0 1.3vh 2vh;
  border-style: solid;
  border-color: transparent transparent transparent #0769F6;
}

.announcement-wrap {
  position: absolute;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
}

.announcement-text {
  display: inline-block;
  white-space: nowrap;
  animation: marquee 10s linear infinite;
  text-decoration: underline;
  color: #0769F6;
}

@keyframes marquee {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.logo-wrap {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 112px;
  height: 46px;
  /* border-radius: 50%; */
  /* background: #0769F6; */
}

.logo-text {
  margin-top: 2px;
font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  text-align: left;
  font-style: normal;
}

.login-wrap {
  height: 60px;
  margin-top: 20px;
  display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login-text {
  width: 150px;
height: 40px;
background: #126FF6;
border-radius: 10px;

  cursor: pointer;
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 40px;
    text-align: center;
    font-style: normal;
}

.userinfo-wrap {
  
}

.username {
  font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    text-align: center;
    font-style: normal;
}

.userlevel {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 182px;
    height: 60px;
    background: linear-gradient(94deg, #DFDFDF 0%, #BEC3D0 100%);
    border-radius: 4px;
}

.userlevel1 {
  background: linear-gradient(90deg, #6AA7FF 0%, #0769F6 100%);
}

.userlevel-img {
  margin-right: 10px;
  width: 61px;
  height: 18px;
}

.userlevel-upgrade {
  position: relative;
  cursor: pointer;
  height: 25px;

  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 12px;
    color: #333333;
    line-height: 25px;
    text-align: center;
    font-style: normal;
}

.upgrade {
width: 80px;
  height: 25px;
  background: #FFFFFF;
  border-radius: 4px;
}

.renewal {
  width: 80px;
    height: 25px;
    background: #FFFFFF;
    border-radius: 4px;
}

.vipfree {
  position: absolute;
  top: -20px;
  right: -12px;
  width: 50px;
    height: 20px;
background: #FB713A;
  border-radius: 10px 7px 7px 0px;
  border: 1px solid #FFFFFF;

    display: flex;
    align-items: center;
    justify-content: center;

    font-family: PingFangSC, PingFang SC;
      font-weight: 500;
      font-size: 12px;
      color: #FFFFFF;
      line-height: 17px;
      text-align: center;
      font-style: normal;
}

.userlevel-count {
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #8E8E8E;
    line-height: 20px;
    text-align: center;
    font-style: normal;
}

.about-wrap {
  cursor: pointer;
  top: 20px;
    position: relative;
  margin-top: 19px;
font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 16px;
  color: #1E77F6;
  line-height: 22px;
  text-align: center;
  font-style: normal;

  display: flex;
  justify-content: space-around;
  width: 100%;
}

.about-item {
  width: 70px;
}

.beian-wrap {
  position: absolute;
  bottom: 30px;
  color: gray;
  font-size: 12px;
}

.wangan-wrap {
  position: absolute;
  bottom: 10px;
  color: gray;
  font-size: 12px;
}


.btn-login {
  position: absolute;
  bottom: 60px;
}

.is-login {
  display: flex;
  align-items: center;

}
.login-icon {
  font-size: 40px;
  /* margin-left: -40px; */
}

.btn-loginout {
  border: 1px solid #979797;
    background: #d8d8d8;
    border-radius: .3em;
    font-size: 1em;
    padding: .4em 2em;
    margin-top: 11em;
    margin-bottom: 2em;
    cursor: pointer;
}
</style>