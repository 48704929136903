<template>
  <div v-show="show" class="tip-upload">
    <img class="pdf-pic" :src="image" alt="">

    <div
      v-if="activeTab === 'Pdf2Word' || activeTab === 'Pdf2Txt' || activeTab === 'Pdf2Excel' || activeTab === 'Pdf2Img' 
  || activeTab === 'PdfExTxt' || activeTab === 'PdfExTable'"
      class="el-upload__text">
      点击下方按钮选择 PDF / 将 PDF 拖入此框，一次仅支持转换一个文件
    </div>
    <div
      v-else-if="activeTab === 'PdfMerger' || activeTab === 'PdfSplit' || activeTab === 'PdfZip'"
      class="el-upload__text">
      点击下方按钮选择 PDF / 将 PDF 拖入此框
    </div>
    <div v-else class="el-upload__text">
      点击下方按钮选择图片 / 将图片拖入此框，一次最多同时上传100张
    </div>

    <div class="el-upload__text">普通会员每日可免费转换{{ count }}{{ tipTxt }}，VIP会员不限制</div>

    <div class="excel-tip" v-if="activeTab === 'Pdf2Excel' || activeTab === 'Img2Excel'">
      注意：系统<span class="green">仅会将PDF中的表格转换至excel</span>，其他非表格内文字/图片等将不进行转换
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadTip",
  props: {
    show: Boolean,
    activeTab: {
      type: String,
      default: 'Pdf2Word'
    }
  },
  data() {
    return {
      image: require('@/assets/img/pdf2word.png'),
      count: 3,
      tipTxt: "页",
      list: {
        Pdf2Word: require('@/assets/img/pdf2word.png'),
        Pdf2Txt: require('@/assets/img/pdf2txt.png'),
        Pdf2Excel: require('@/assets/img/pdf2excel.png'),
        Pdf2Img: require('@/assets/img/pdf2img.png'),

        Img2Word: require('@/assets/img/img2word.png'),
        Img2Txt: require('@/assets/img/img2txt.png'),
        Img2Excel: require('@/assets/img/img2excel.png'),
        Img2Pdf: require('@/assets/img/img2pdf.png'),

        PdfMerger: require('@/assets/img/pdfmerger.png'),
        PdfSplit: require('@/assets/img/pdfsplit.png'),
        PdfZip: require('@/assets/img/pdfzip.png'),
        PdfExTxt: require('@/assets/img/pdfextxt.png'),
        PdfExTable: require('@/assets/img/pdfextable.png'),
        PdfExImg: require('@/assets/img/pdfeximg.png'),

        ImgSplice: require('@/assets/img/imgsplice.png'),
        ImgZip: require('@/assets/img/imgzip.png'),
        ImgExTxt: require('@/assets/img/imgextxt.png'),
        ImgExTable: require('@/assets/img/imgextable.png'),
        ImgExImg: require('@/assets/img/imgeximg.png'),
      }
    }
  },
  watch: {
    activeTab(nVal) {
      this.image = this.list[nVal];
      if (nVal == 'PdfMerger' || nVal == 'PdfSplit' || nVal == 'PdfZip' || nVal == 'ImgZip') {
        this.count = 1;
      } else {
        this.count = 3;
      }

      if (nVal == "Pdf2Word" || nVal === 'Pdf2Txt' || nVal === 'Pdf2Excel' || nVal === 'Pdf2Img' || nVal === 'PdfExTxt' || nVal === 'PdfExTable') {
        this.tipTxt = "页";
      } else if (nVal == "Img2Word" || nVal === 'Img2Txt' || nVal === 'Img2Excel' || nVal === 'Img2Pdf' || nVal === 'ImgSplice' || nVal === 'ImgExTxt' || nVal === 'ImgExTable') {
        this.tipTxt = "张";
      } else {
        this.tipTxt = "次";
      }
    }
  }
} 
</script>

<style scoped>
.tip-upload {
  margin-top: 248px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-pic {
  margin-bottom: 32px;
  height: 167px;
}

.excel-tip {
  margin-top: 5px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 18px;
    color: #A8A8A8;
    line-height: 25px;
    text-align: left;
    font-style: normal;
}

.green {
  color: #3CAA74;
}
</style>