<template>
  <el-dialog title="" :visible.sync="visible">
    <div class="view-columns">
      <div class="viplist-wrap">
        <div class="viplist-title-wrap">
          <div class="viplist-title-content">
            <!-- <div class="account">{{ userInfo.account }}</div> -->
            <img class="userlevel-img"
              :src="userInfo.vipLevel == 0 ? require('@/assets/img/vip0.png') : require('@/assets/img/vip1.png')"
              alt="VIP">
            <div v-if="userInfo.vipLevel > 0">到期日期：{{ userInfo.vipEndTime }}</div>
            <div class="account-logout" @click="handleLogout">退出登录</div>
          </div>

          <div class="tab-wrap">
            <div v-if="currentTab == 0" class="tab-content">
              <div class="tab-vip tab-vip-active" @click="handleTabSelect(0)">
                <span class="tab-vip-active-txt">VIP会员</span>
              </div>
              <div class="tab-invite" @click="handleTabSelect(1)">
                <span class="tab-invite-txt">限时活动，会员免费送</span>
              </div>
            </div>
            <div v-else class="tab-content">
              <div class="tab-vip" @click="handleTabSelect(0)">
                <span class="tab-vip-txt">VIP会员</span>
              </div>
              <div class="tab-invite tab-invite-active" @click="handleTabSelect(1)">
                <span class="tab-invite-active-txt">限时活动，会员免费送</span>
              </div>
            </div>
          </div>
        </div>

        <div class="viplist-content">
          <div v-if="currentTab == 0" class="viplist-item-wrap">
            <div class="viplist-item" :class="item.select ? 'item-select' : ''" v-for="(item, index) in vipList"
              :key="index" @click="handleSelectItem(item)">
              <div v-if="item.vipName === 'VIP月卡'" class="suprice">新用户专享</div>
              <div v-if="item.vipName === 'VIP终身卡'" class="suprice promotions">新年促销价</div>

              <div class="item-title-wrap">
                <div class="viplist-item-title">{{ item.vipName }}</div>
                <div class="viplist-item-des">不限制转换次数</div>
              </div>
              <div class="item-price-wrap">
                <div class="viplist-item-rmb">¥</div>
                <div class="viplist-item-price">{{ item.money }}</div>

                <div v-if="item.vipName === 'VIP终身卡'" class="viplist-item-orgprice">¥88</div>
                <div v-if="item.vipName === 'VIP终身卡'" class="viplist-item-countdown">距离结束：{{ countdown.hours }}:{{ countdown.minutes }}:{{ countdown.seconds }}</div>
              </div>
            </div>
          </div>
          <div v-else class="invite-list-content">
            <div class="invite-list-title">邀请记录</div>
            <div class="invite-list-wrap">
              <el-table ref="tableRef" class="invite-list-table" :data="tableData" border height="250"
                @scroll="handleScroll" empty-text="暂无邀请记录">
                <el-table-column prop="regUserMobile" label="用户编号" width="100"></el-table-column>
                <el-table-column prop="createTime" label="成功注册时间" width="130" :formatter="formatDate"></el-table-column>
                <el-table-column prop="orgVipEndTime" label="原会员到期日" width="130"
                  :formatter="formatDate"></el-table-column>
                <el-table-column prop="newVipEndTime" label="现会员到期日" width="130"
                  :formatter="formatDate"></el-table-column>
              </el-table>

              <div v-if="isLoading" class="invite-list-tip">加载中...</div>
              <div v-else-if="noMoreData && tableData.length > 0" class="invite-list-tip">
                <span>没有更多数据了</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-show="currentTab == 0" class="pay-wrap">
        <div class="pay-content">
          <div class="pay-title">支付方式:</div>
          <div class="pay-mode">
            <!-- <div class="pay-item" :class="payChannel==2?'pay-item-active' : ''" @click="handlePayChannel(2)">
            <img class="pay-item-icon" src="../assets/img/alipay.png" alt="">
            <div class="pay-item-title">支付宝支付</div>
          </div> -->
            <div class="pay-item" :class="payChannel == 1 ? 'pay-item-active' : ''" @click="handlePayChannel(1)">
              <img class="pay-item-icon" src="../assets/img/wxpay.png" alt="">
              <div class="pay-item-title">微信支付</div>
            </div>
          </div>

          <div class="pay-price">
            <div class="pay-price-text">支付</div>
            <div class="pay-price-rmb">¥</div>
            <div class="pay-price-title">{{ selectItem.money }}</div>
          </div>

          <div class="pay-code">
            <canvas id="qrcode"></canvas>

            <div class="pay-time">
              <img class="pay-time-icon" src="../assets/img/timepay.png" alt="">
              <div class="pay-time-title">{{ expireTime }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="currentTab == 1" class="activity-wrap">
        <div class="activity-explan-wrap">
          <div class="activity-title">活动说明</div>
          <div class="activity-des-wrap">
            <div class="activity-des">新用户注册时填写<span class="activity-des-strong">您的邀请码并成功注册</span></div>
            <div class="activity-des">每邀请一名新用户成功注册<span class="activity-des-strong">赠送一个月会员</span>时长</div>
            <div class="activity-des">新用户邀请不限制数量，<span class="activity-des-strong">赠送会员时长可叠加</span></div>
          </div>
        </div>

        <div class="invite-code-wrap">
          <div class="invite-code-title">我的邀请码：{{ inviteCode }}</div>

          <div class="invite-code-txt">{{ inviteTxt }}</div>
          <div class="invite-code-txt">输入以上邀请码注册即送一个月全站会员。</div>

          <div class="invite-code-copy" @click="copyToClipboard">复制邀请文案</div>
        </div>

      </div>

    </div>
  </el-dialog>
</template>

<script>
import QRious from 'qrious';

export default {
  data() {
    return {
      currentTab: 0,
      visible: false,
      userInfo: {
        account: '',
        vipLevel: 0,
        dayUseCount: 0,
        vipEndTime: '',
      },
      vipList: [],
      selectItem: {
        id: '',
        money: '',
        vipName: '',
        supFlag: 1
      },
      payChannel: 1,
      expireTime: '',
      inviteCode: '',
      inviteTxt: '',
      tableData: [], // 表格数据
      currentPage: 1, // 当前页码
      pageSize: 100, // 每页数据量
      isLoading: false, // 是否正在加载
      noMoreData: false, // 是否没有更多数据
      countDownTimer: null,
      countdown: {
        hours: '00',
        minutes: '00',
        seconds: '00',
      }
    }
  },
  watch: {
    visible(val) {
      if (!val) {
        console.log("visible false");
        this.currentPage = 1;
        this.isLoading = false;
        this.noMoreData = false;
        this.tableData = [];

        this.stopCountdown();
        this.stopOrderCountdown();
        this.stopPromotionsCountdown();
      }
    }
  },
  mounted() {
    console.log("mounted");
  },
  beforeDestroy() {
    console.log("beforeDestroy");
  },
  methods: {
    init(userInfo) {
      this.visible = true;
      console.log("init", userInfo);
      this.userInfo = userInfo;
      this.currentTab = 0;
      this.$nextTick(() => {
        this.inviteCode = this.userInfo.inviteCode;
        let currentUrl = window.location.href;
        this.inviteTxt = `免费好用的PDF转换工具，支持常用的文档格式转换。${currentUrl} 邀请码：${this.inviteCode}`
        this.getVipList();

        this.startPromotionsCountdown();
      })
    },
    handleLogout() {
      this.$confirm('是否退出登录?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$cookies.remove('token');
          this.$emit('logout');
          this.$message({
            message: '退出成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.visible = false
            }
          })
        }).catch(() => {

        });
    },
    handleSelectItem(item) {
      this.selectItem.select = false;
      item.select = true;
      this.selectItem = item;      
      this.buyVip();
    },
    handlePayChannel(payChannel) {
      this.payChannel = payChannel;
      this.buyVip();
    },
    handleTabSelect(index) {
      this.currentTab = index;

      console.log("handleTabSelect", index, this.tableData.length);
      if (index == 1 && this.tableData.length == 0) {

        this.getInviteList();
      }
    },
    async getVipList() {
      let res = await this.$api.getVipList();

      if (res.data.code == 0) {
        this.vipList = res.data.vipList;
        if (this.vipList.length > 0) {
          this.vipList[0].select = true;
          this.selectItem = this.vipList[0];
          this.buyVip();
        }
      }
    },
    getExistOrder() {
      let res = localStorage.getItem(`order${this.$H.userInfo.uid}-${this.selectItem.id}-${this.payChannel}`);
      if (res) {
        res = JSON.parse(atob(res));
        const now = new Date().getTime();
        let expireTime = new Date(res.data.expireTime).getTime();
        const distance = expireTime - now;
        
        console.log("getExistOrder distance", distance);
        if (distance < 0) {
          return null;
        }

        return res;
      }
      
      return null;
    },
    async buyVip() {
      console.log("buyVip selectItem", this.selectItem);
      console.log("buyVip payChannel", this.payChannel);
      let res = this.getExistOrder();
      console.log("buyVip getExistOrder res", res);
    
      if (!res) {
        res = await this.$api.buyVip({ buyVipId: this.selectItem.id, payChannel: this.payChannel });
        console.log("buyVip res", res);
      }

      if (res.data.code == 0) {
        localStorage.setItem(`order${this.$H.userInfo.uid}-${this.selectItem.id}-${this.payChannel}`, btoa(JSON.stringify(res)));

        this.selectItem.orderId = res.data.orderId;
        this.generateQRCode(res.data.codeUrl);
        this.calcExpireTime(res.data.expireTime);
        this.startCheckOrderStatus();
      } else {
        this.hideQRCode();
        this.stopCountdown();
        this.expireTime = '';
      }
    },
    generateQRCode(codeUrl) {
      const qrCodeCanvas = document.getElementById('qrcode');
      if (qrCodeCanvas) {
        qrCodeCanvas.style.display = 'block'; // 显示二维码
        const qr = new QRious({
          element: qrCodeCanvas,
          value: codeUrl,
          size: 172 // 调整二维码大小
        });
      }
    },
    hideQRCode() {
      const qrCodeCanvas = document.getElementById('qrcode');
      if (qrCodeCanvas) {
        qrCodeCanvas.style.display = 'none'; // 从DOM中移除二维码
      }
    },
    setExpireTime(expireTime) {
      const now = new Date().getTime();
      const distance = expireTime - now;

      if (distance < 0) {
        this.stopCountdown();
        this.expireTime = "支付时间已过期";
        this.buyVip();
        return;
      }

      const hours = String(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
      const minutes = String(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
      const seconds = String(Math.floor((distance % (1000 * 60)) / 1000)).padStart(2, '0');

      this.expireTime = `支付剩余时间：${hours}:${minutes}:${seconds}`;
    },
    calcExpireTime(expireTime) {
      this.stopCountdown();
      let time = new Date(expireTime).getTime();
      this.setExpireTime(time);
      this.interval = setInterval(() => {
        this.setExpireTime(time);
      }, 1000);
    },
    stopCountdown() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    },
    startCheckOrderStatus() {
      this.stopOrderCountdown();
      this.intervalOrder = setInterval(() => {
        this.checkOrderStatus();
      }, 3000);
    },
    stopOrderCountdown() {
      if (this.intervalOrder) {
        clearInterval(this.intervalOrder);
      }
    },
    async checkOrderStatus() {
      let res = await this.$api.checkOrderStatus({ orderId: this.selectItem.orderId });

      if (res.data.code === 0 && res.data.orderStatus === 1) {
        localStorage.removeItem(`order${this.$H.userInfo.uid}-${this.selectItem.id}-${this.payChannel}`);
        this.stopOrderCountdown();
        this.$emit('purchase');
        this.$message({
          message: '支付成功',
          type: 'success',
          duration: 1000,
          onClose: () => {
            this.visible = false
          }
        })
      }
    },
    async getInviteList() {
      if (this.isLoading || this.noMoreData) return;

      this.isLoading = true;
      try {
        let res = await this.$api.inviterlist({ limit: this.pageSize, page: this.currentPage });
        if (res.data.code == 0 && res.data.page && res.data.page.list && res.data.page.list.length > 0) {   
          this.tableData = [...this.tableData, ...res.data.page.list];
          this.currentPage++;
        } else {
          this.noMoreData = true; // 没有更多数据
        }
      } catch (error) {
        console.error("加载数据失败", error);
      } finally {
        this.isLoading = false;
      }
    },
    handleScroll(event) {
      console.log('handleScroll', event);
      const scrollTop = event.target.scrollTop;
      const scrollHeight = event.target.scrollHeight;
      const clientHeight = event.target.clientHeight;

      // 当滚动接近底部时加载更多数据
      if (scrollHeight - scrollTop - clientHeight < 20) {
        this.getInviteList();
      }
    },
    async copyToClipboard() {
      if (navigator.clipboard) {
        try {
          // 使用 Clipboard API 复制文字
          await navigator.clipboard.writeText(this.inviteTxt);
          this.$message.success("复制成功！");
        } catch (error) {
          console.error("复制失败：", error);
          this.$message.error("复制失败，请检查权限或尝试其他浏览器！");
        }
      } else {
        console.error('Clipboard API 不支持');
        this.$message.error("Clipboard API 不支持");
      }
    },
    formatDate(row, column, cellValue, index) {
      if (!cellValue) return ''; // Check if the cell value exists
      const date = new Date(cellValue); // Convert to Date object
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Get month, ensure 2 digits
      const day = date.getDate().toString().padStart(2, '0'); // Get day, ensure 2 digits
      return `${year}-${month}-${day}`; // Return formatted date string
    },
    startPromotionsCountdown() {
      this.updatePromotionsTime(); // 初始化倒计时
      this.countDownTimer = setInterval(this.updatePromotionsTime, 1000); // 每秒更新一次
    },
    updatePromotionsTime() {
      const now = new Date();
      const endOfDay = new Date(now);
      endOfDay.setHours(24, 0, 0, 0); // 设置到当天的24点（即0点）

      let remainingTime = endOfDay - now; // 计算剩余时间（毫秒）

      if (remainingTime <= 0) {
        remainingTime = 0;
        this.resetPromotionsCountdown(); // 重置倒计时
      }

      const hours = String(Math.floor(remainingTime / 3600000)).padStart(2, '0');
      const minutes = String(Math.floor((remainingTime % 3600000) / 60000)).padStart(2, '0');
      const seconds = String(Math.floor((remainingTime % 60000) / 1000)).padStart(2, '0');

      this.countdown.hours = hours;
      this.countdown.minutes = minutes;
      this.countdown.seconds = seconds;
    },
    resetPromotionsCountdown() {
      this.stopPromotionsCountdown();
      this.startCountdown(); // 从头开始新的倒计时
    },
    stopPromotionsCountdown() {
      if (this.countDownTimer) {
        clearInterval(this.countDownTimer); // 清除当前倒计时
      }
    }
  }
}
</script>

<style scoped>
/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
  width: 814px;
}

/deep/ .el-dialog__body {
  padding: 0;
  height: 432px;
}
.view-columns {
  position: relative;
  /* height: 50vh; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;

  justify-content: flex-start;
  align-items: flex-start;
  font-size: 1.2em;
}

.viplist-wrap {
  width: 540px;
}

.viplist-title-wrap {
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
    height: 102px;
    background: #086AF6;

    font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: #FFFFFF;
      line-height: 17px;
      text-align: left;
      font-style: normal;
}

.viplist-title-content {
  margin-top: 20px;
display: flex;
  align-items: center;
}

.account {
    margin-left: 30px;
  margin-right: 9px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 25px;
    text-align: left;
    font-style: normal;
    max-width: 200px;
}

.userlevel-img {
  margin-left: 30px;
  margin-right: 19px;
  width: 61px;
    height: 18px;
}

.account-logout {
  cursor: pointer;
  position: absolute;
  right: 20px;
}

.tab-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 380px;
  height: 37px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-content {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
}

.tab-vip {
  position: absolute;
  left: 0;
  top: 0;
  width: 205px;
  height: 100%;
  background: #3B82F5;
    border-radius: 10px 10px 0px 0px;
  
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #FFFFFF;
  line-height: 37px;
  text-align: left;
  font-style: normal;
}

.tab-vip-active {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 18px;
  color: #086AF6;
  line-height: 37px;
  text-align: left;
  font-style: normal;
  z-index: 1;

  background: url("../assets/img/tab-vip.png");
  background-size: 205px 37px;
}

.tab-vip-active-txt {
  margin-left: 56px;
}

.tab-vip-txt {
  margin-left: 64px;
}

.tab-invite {
  position: absolute;
  right: 0;
  top: 0;
  width: 221px;
  height: 100%;
    background: #3B82F5;
      border-radius: 10px 10px 0px 0px;

  letter-spacing: -2px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #FFFFFF;
    line-height: 37px;
    text-align: right;
    font-style: normal;
}

.tab-invite-active {
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #086AF6;
    line-height: 37px;
    text-align: right;
    font-style: normal;
    background: #086AF6;
    z-index: 1;

    background: url("../assets/img/tab-invite.png");
      background-size: 221px 37px;
}

.tab-invite-active-txt {
  margin-right: 37px;
}

.tab-invite-txt {
  margin-right: 13px;
}

.viplist-content {
  position: relative;
  height: 330px;
  width: 100%;
}

.viplist-content-title {
  margin-top: 12px;
  font-family: Arial, Arial;
    font-weight: 900;
    font-size: 20px;
    color: #424242;
    line-height: 28px;
    text-align: center;
    font-style: normal;
}

.viplist-content-radius {
      z-index: 1;
  position: absolute;
  top: -14px;
  width: 186px;
    height: 46px;
    background: #FFFFFF;
    border-radius: 10px 20px 14px 14px;
}

.viplist-item-wrap {
position: relative;
  top: 30px;
  margin-left: 40px;
  z-index: 1;
  max-height: 270px;
  overflow-y: scroll;
  scrollbar-width: none;
  /* width: 100%; */
  /* height: auto; */
  padding-top: 25px;
}

.viplist-item-wrap::-webkit-scrollbar {
  display: none;
  /* Chrome 和 Safari 隐藏滚动条 */
}

.viplist-item {
  cursor: pointer;
  position: relative;
  padding-left: 31px;
  padding-right: 24px;
  margin-bottom: 20px;
  width: 414px;
    height: 100px;
    background: #F9F9F9;
    border-radius: 5px;

    display: flex;
    justify-content: space-between;
    align-items: center;
}

.item-select {
  background: linear-gradient(180deg, #FFE7B4 0%, #FFE59B 100%);
}

.suprice {
  z-index: 1;
  position: absolute;
  left: 0;
  top: -14px;
  height: 28px;

  padding: 0 20px;
  background: #DDC375;
  border-radius: 10px 0 10px 0;

  font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 28px;
  text-align: left;
  font-style: normal;
}

.promotions {
  background: linear-gradient( 270deg, #FF5656 0%, #E68D0D 100%);
}

.item-title-wrap {
  display: flex;
  flex-direction: column;
}

.viplist-item-title {
  margin-bottom: 6px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #4D0818;
    line-height: 28px;
    text-align: left;
    font-style: normal;
}

.viplist-item-des {
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #4D0818;
    line-height: 20px;
    text-align: left;
    font-style: normal;
}

.item-price-wrap {
  position: relative;
  display: flex;
}

.viplist-item-rmb {
  position: relative;
  top: 7px;
    right: 15px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 24px;
    color: #4D0818;
    line-height: 33px;
    text-align: left;
    font-style: normal;
}

.viplist-item-price {
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 48px;
    color: #4D0818;
    line-height: 33px;
    text-align: left;
    font-style: normal;
}

.viplist-item-orgprice {
  position: relative;
  top: 17px;
  margin-left: 5px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #A6612E;
  line-height: 22px;
  text-align: left;
  font-style: normal;
  text-decoration: line-through
}

.viplist-item-countdown {
  position: absolute;
  bottom: -32px;
  right: 0;
  white-space: nowrap;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 16px;
  color: #A6612E;
  line-height: 22px;
  text-align: left;
  font-style: normal;
}

.pay-wrap {
  flex: 1;
  background: #F4F8FF;
  height: 100%;

    display: flex;
  flex-direction: column;
  align-items: center;
}

.pay-content {
  padding-top: 26px;
  width: 252px;
}

.pay-title {
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    text-align: left;
    font-style: normal;
}

.pay-mode {
  display: flex;
  margin-top: 6px;
  margin-bottom: 20px;
}

.pay-item {
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 118px;
    height: 28px;
    border: 1px solid #DBDBDB;

  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 20px;
    text-align: left;
    font-style: normal;
}

.pay-item-active {
  border: 1px solid #086AF6;
}

.pay-item-icon {
  margin-right: 10px;
  width: 18px;
  height: 18px;
}

.pay-price {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 18px;
    color: #4D0818;
    line-height: 25px;
    text-align: left;
    font-style: normal;
}

.pay-price-text {
  position: relative;
  top: 3px;
  left: -5px;
}

.pay-price-rmb {
  font-size: 24px;
}

.pay-price-title {
  font-size: 56px;
  line-height: 46px;
}

.pay-code {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pay-paycode {
  width: 172px;
  height: 172px;
}

.pay-time {
  /* margin-top: 10px; */
  display: flex;
  align-items: center;
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #DE5245;
    line-height: 20px;
    text-align: left;
    font-style: normal;
}

.pay-time-icon {
  margin-left: 2px;
  width: 16px;
  height: 16px;
}

.invite-list-content {
  padding: 24px 30px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.invite-list-title {
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 14px;
    color: #424242;
    line-height: 20px;
    text-align: center;
    font-style: normal;
}

.invite-list-table {
  width: 100%;
  overflow-y: auto;
}

.invite-list-tip {
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #424242;
    line-height: 17px;
    text-align: center;
    font-style: normal;
  text-align: center;
    padding: 10px;
}

.activity-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F4F8FF;
    height: 100%;
}

.activity-explan-wrap {
  padding-top: 34px;
  width: 252px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 181px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.activity-title {
  margin-bottom: 20px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    text-align: center;
    font-style: normal;
}

.activity-des-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.activity-des {
  margin-bottom: 10px;
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #333333;
    line-height: 17px;
    text-align: center;
    font-style: normal;
}

.activity-des-strong {
  color: #086AF6;
}

.invite-code-wrap {
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
font-family: PingFangSC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #333333;
  line-height: 17px;
  text-align: left;
  font-style: normal;
}

.invite-code-title {
  margin-bottom: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 18px;
  color: #333333;
  line-height: 25px;
  text-align: left;
  font-style: normal;
}

.invite-code-txt {
  margin-bottom: 10px;
  width: 252px;
  text-align: left;
}

.invite-code-copy {
  cursor: pointer;
  margin-top: 10px;
  color: #086AF6;
}

/deep/ .el-table--border {
  border: none;
}

/deep/ .el-table .cell {
  font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 12px;
    color: #424242;
    line-height: 17px;
    text-align: center;
    font-style: normal;
}
/deep/ .el-table--border .el-table__cell {
  border-right: none;
}

/deep/ .el-table--border::after {
  width: 0;
}
</style>