<template>
  <div class="upload-area">
    <div class="upload-demo upload-wrap">
      <Upload ref="uploadRef" v-loading="loading" :element-loading-text="loadingText"
        element-loading-background="rgba(0, 0, 0, 0.2)" class="upload-demo" drag multiple list-type="picture"
        :file-list="fileList" :auto-upload="false" action="" :on-change="handleChange" :on-remove="handleRemove"
        :limit="limitCount" :show-file-list="showFileList" :on-picedit="handlePicEdit" :on-checkbox="handleCheckbox"
        accept="application/pdf" :activeTab="activeTab">
      </Upload>

      <div v-if="fileList.length > 0 && !ocrInProgress && !ocrSuccess" class="checkall-wrap">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
      </div>

      <UploadTip :show="fileList.length == 0" :activeTab="activeTab"></UploadTip>

      <div class="btn-wrap">
        <div class="btn-img" :class="{ 'btn-img-active': fileList.length > 0 }" @click.stop="handleExport(0)">单个输出
        </div>
        <div class="btn-img" :class="{ 'btn-img-active': fileList.length > 0 }" @click.stop="handleExport(1)">生成长图
        </div>
      </div>

      <div class="radio-wrap">
        <el-radio-group v-model="radio" @input="handleRadioChange" :disabled="!fileList.length">
          <el-radio-button label="正序"></el-radio-button>
          <el-radio-button label="倒序"></el-radio-button>
          <el-radio-button label="清空"></el-radio-button>
        </el-radio-group>

        <FilePos :fileList="fileList"></FilePos>
      </div>
    </div>

    <ImageEditModal v-if="showModal" :show="showModal" :imageUrl="imageSrc" @close="closeModal"
      @cropped="handleCroppedImage" />
    <PopImgSaveType ref="popImgSaveType" v-if="popImgSaveTypeVisible" :fileList="fileList"></PopImgSaveType>
  </div>
</template>

<script>
import Vue from 'vue';
import Upload from './../upload';
import UploadTip from './../common/UploadTip';
import FilePos from './../common/FilePos';
import PopImgSaveType from './../common/PopImgSaveType.vue';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import ImageEditModal from './../ImageEditModal.vue';
import { isLoggedIn, isNoDayUseCount } from '@/utils/authUtils';
import '@/utils/dateUtils.js';

const MAX_IMAGE = 10000;

export default {
  components: {
    Upload,
    ImageEditModal,
    UploadTip,
    FilePos,
    PopImgSaveType
  },
  name: 'UploadArea',
  props: {
    activeTab: {
      type: String,
      required: true,
    },
    tabName: String,
  },
  data() {
    return {
      ocrSuccess: false,
      ocrInProgress: false,
      btnTxt: "开始识别",
      dialogVisible: false,
      loading: false,
      loadingText: '',
      limitCount: MAX_IMAGE,
      showFileList: true,
      fileList: [
        // { 
        // name: 'food.jpeg', 
        // url: 'https://otisimg.oss-cn-hangzhou.aliyuncs.com/tmp/5_1654_2338.jpg' 
        // }
      ],
      files: [
        {
          "fileUrl": "https://otisimg.oss-cn-hangzhou.aliyuncs.com/tmp/5_1654_2338.jpg",
          "progress": 0,
          "resultUrl": "https://otisimg.oss-cn-hangzhou.aliyuncs.com/Pdf2Word/test.docx"
        }
      ],
      mainTaskIdList: [],
      showModal: false,
      imageSrc: '',
      cropperFile: null,
      radio: '正序',
      previewDocxVisible: false,
      popImgSaveTypeVisible: false,
      pdf2ImagesIng: false, //pdf转图片中
      isIndeterminate: false,
      checkAll: false,
    };
  },
  methods: {
    checkUserVip() {
      if (!isLoggedIn(this.$cookies)) {
        console.log("未登录");
        this.$emit('notify', { message: '未登录', type: 'login' });
        return false;
      }

      if (isNoDayUseCount(this.$H.userInfo)) {
        console.log("升级会员");
        this.$emit('notify', { message: '升级会员', type: 'purchase' });
        return false;
      }

      return true;
    },
    handleExport(type) {
      console.log("handleExport");
      if (!this.checkUserVip()) {
        return;
      }

      if (this.fileList.length == 0) {
        return;
      }

      this.showPopImgSaveType(type);
    },
    handleChange(file, fileList) {
      console.log("handleChange file", file);
      console.log("handleChange", fileList);
      if (file && file.raw && file.raw.type !== 'application/pdf') {
        this.$message.error('请选择PDF文件');
        this.fileList = [];
        return false;
      }

      var done = () => {
        if (!this.pdf2ImagesIng) {
          if (fileList.length > 1) {
            fileList.splice(0, 1);
          }
          this.showFileList = false;
          this.pdf2Images(file.raw);
          return;
        }
      };

      if (this.ocrSuccess || this.ocrInProgress) {
        this.fileList = fileList.splice(0, fileList.length - 1);
        this.$confirm('上传新文档需清空当前文档，是否继续？')
          .then(() => {
            this.initOcr();
            done();
          })
          .catch(() => { });
        return;
      }

      done();
    },
    handleRemove(file, fileList) {
      console.log("handleRemove");
      this.fileList = fileList;

      if (fileList.length == 0) {
        this.initOcr();
      }
    },
    initOcr() {
      this.limitCount = MAX_IMAGE;
      this.ocrSuccess = false;
      this.ocrInProgress = false;
      this.fileList = [];
      this.btnTxt = "开始识别";
      this.stopAllInterval();
    },
    handleResetOCR() {
      this.initOcr();
      this.$nextTick(() => {
        this.radio = '正序';
      });

    },
    handlePicEdit(file) {
      console.log("handlePicEdit file", file);
      this.openModal(file);
    },
    handleRadioChange(radio) {
      console.log("handleRadioChange", radio)
      if (radio == '正序' || radio == '倒序') {
        if (this.fileList && this.fileList.length > 0)
          this.fileList.reverse();
      } else if (radio == '清空') {
        setTimeout(() => {
          this.handleResetOCR();
        }, 500);
      }
    },
    handleCheckbox() {
      console.log("UploadArea handleCheckbox");
      let checkedCount = 0;
      this.fileList.forEach(file => {
        if (file.checked) {
          checkedCount++;
        }
      });

      this.checkAll = checkedCount == this.fileList.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.fileList.length;
    },
    handleCheckAllChange(val) {
      this.isIndeterminate = false;
      this.fileList.forEach(file => {
        file.checked = val;
      });
    },
    isPreviewDialogVisible() {
      return this.$refs.previewDocx && this.$refs.previewDocx.dialogVisible;
    },
    stopAllInterval() {
      this.mainTaskIdList.map(item => {
        clearInterval(item.intervalId);
      });
    },
    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ab], { type: mimeString });
    },
    async pdf2Images(file) {
      if (file && file.type === 'application/pdf') {
        this.pdf2ImagesIng = true;
        this.showLoading();
        const fileReader = new FileReader();

        fileReader.onload = async (e) => {
          const typedArray = new Uint8Array(e.target.result);
          // 设置 PDF.js worker
          pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
          // 加载 PDF 文件
          this.pdfDoc = await pdfjsLib.getDocument(typedArray).promise;
          // 渲染所有页面为 JPG 图片
          this.fileList = [];
          this.showFileList = true;
          this.checkAll = true;
          let fileType = '';
          let raw = null;
          for (let i = 1; i <= this.pdfDoc.numPages; i++) {
            const page = await this.pdfDoc.getPage(i);
            const textContent = await page.getTextContent();

            const viewport = page.getViewport({ scale: 2.8 }); // 调整缩放比例
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.width = viewport.width;
            canvas.height = viewport.height;

            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };

            // 渲染页面到 Canvas
            await page.render(renderContext).promise;


            const jpgImage = canvas.toDataURL('image/jpeg', 1.0);
            fileType = 'image';
            raw = await fetch(jpgImage).then(res => res.blob());


            this.fileList.push({ url: jpgImage, raw: raw, checked: true, page: i, fileType: fileType });
            if (i == this.pdfDoc.numPages) {
              this.pdf2ImagesIng = false;
              this.hideLoading();
            }
          }
        };

        fileReader.readAsArrayBuffer(file);
      }
    },
    showLoading(text) {
      this.fullscreenLoading = this.$loading({
        lock: true,
        text: text,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
    },
    hideLoading() {
      if (this.fullscreenLoading)
        this.fullscreenLoading.close();
    },
    showPopImgSaveType(type) {
      this.popImgSaveTypeVisible = true;
      this.$nextTick(() => {
        this.$refs.popImgSaveType.init(type);
      });
    },
    openModal(file) {
      this.showModal = true;
      this.imageSrc = file.srcUrl || file.url;
      this.cropperFile = file;
    },
    closeModal() {
      this.showModal = false;
    },
    handleCroppedImage(croppedImage) {
      console.log('裁剪后的图片:', croppedImage);
      // 你可以在这里上传裁剪后的图片到 OSS 或其他存储
      if (!this.cropperFile.srcUrl) {
        this.cropperFile.srcUrl = this.cropperFile.url;
        this.cropperFile.srcRaw = this.cropperFile.raw;
      }
      this.cropperFile.url = croppedImage;
      this.cropperFile.raw = this.dataURItoBlob(croppedImage);
      Vue.set(this.fileList, this.fileList.indexOf(this.cropperFile), this.cropperFile);
      this.closeModal();
    },
  }
}
</script>

<style scoped>
@import '@/assets/css/uploadarea.css';
.btn-wrap {
  
  z-index: 1;
  width: 410px;
  height: 50px;
  position: absolute;
  top: 41px;
  right: 32px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn-img {
  width: 200px;
  height: 50px;
  background: #CBCBCB;
  border-radius: 10px;

  font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 50px;
    text-align: center;
    font-style: normal;
}

.btn-img-active {
  background: #0769F6;
}
</style>